import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { confirmEmailChange } from '../../API/apiService';
import { useNavigate } from 'react-router-dom';

const EmailChangeConfirmation = () => {
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    const newEmail = queryParams.get('newEmail');
    const token = queryParams.get('token');
  
    // Confirm email change if query parameters are present
    if (userId && newEmail && token) {
      const confirmEmail = async () => {
        try {
          const response = await confirmEmailChange(userId, newEmail, token);

          if(response.success){
            localStorage.removeItem('userVirtunex')
           navigate('/email-confirmed')
          }
          
        } catch (err) {

        }
      };
  
      confirmEmail();
    }
  }, [location.search]);  // Dependency array includes location.search to trigger effect
  

  return (
    <div style={{height:'100hv', marginTop:'500px'}}>
      {status && <p>{status}</p>}
      {error && <p>{error}</p>}
    </div>
  );
};

export default EmailChangeConfirmation;
