import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { createBrowserHistory } from "history";
import toast from "react-hot-toast";
import i18n from '../i18n'; 



export const history = createBrowserHistory();

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL, // Replace with your API base URL
  // timeout: 10000, // Optional: set a timeout for requests
  headers: {
    "Content-Type": "application/json",
    "X-Content-Type-Options": "nosniff", // Prevent MIME-type sniffing
    "X-Frame-Options": "DENY",           // Prevent clickjacking by not allowing the site to be framed
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains", // Enforce HTTPS for 1 year
    "X-XSS-Protection": "1; mode=block", // Enable XSS filtering in browsers
    "Referrer-Policy": "no-referrer",    // Prevent referrer info from being sent
    "Permissions-Policy": "geolocation=(), microphone=(), camera=()", // Limit browser features
   
  },
});


export const redirectToLogin = () => {
  history.push("/login");
};

apiClient.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage
    const token = getToken();

    // If the token exists, add it to the headers
    const noAuthEndpoints = ["/login", "/register"];

    // Check if the request URL is in the list of endpoints that don't require a token
    const requiresAuth = !noAuthEndpoints.includes(config.url);
    if (requiresAuth && token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

import { getCurrentLanguage } from '../Helpers/helpers';


apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Network Error Handling
    if (!error.response) {
      // Handle network error
      toast.error(i18n.t("apiException.Network Error"));
     // window.location.href = "/server-error";
      return Promise.reject(i18n.t("apiException.Network Error"));
    }

    // Unauthorized (401) Handling and Token Refresh
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refToken = await refreshToken();
        if (refToken) {
          // Get the new access token and retry the request
          const newAccessToken = getToken();
          const currentUser = getCurrentUser();
          currentUser["token"] = newAccessToken;

          localStorage.removeItem("userVirtunex");
          localStorage.setItem("userVirtunex", JSON.stringify(currentUser)); // Set new access token
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          // Retry the API call with the new token
          return axios(originalRequest);
        }
      } catch (refreshError) {
        alert(i18n.t("apiException.Session expired"));
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }

    // Handle other HTTP status codes
    if (error.response.status >= 500) {
     // toast.error(i18n.t("apiException.Something went wrong"));
    } else if (error.response.status >= 400) {
      return Promise.reject(error);
    } else {
      toast.error(i18n.t("apiException.Something went wrong"));
    }

  
  }
);


async function refreshToken() {
  try {
    const user = getCurrentUser();
    if (user === null) {
      window.location.href = "/login";
      return;
    }
    const response = await apiClient.get(
      `/refresh-token/${user.id}`,
      {},
      { withCredentials: true }
    );

    user.token = response.data.data;
    localStorage.setItem("userVirtunex", JSON.stringify(user));
    return true;
  } catch (error) {
    toast("Sesion expired, please re-login");
    localStorage.removeItem("userVirtunex");
    window.location.href = "/login";
  }
}

const getTokenExpiry = (token) => {
  const decodedToken = jwtDecode(token);

  return decodedToken.exp; // Convert to milliseconds
};

const getToken = () => {
  var user = JSON.parse(localStorage.getItem("userVirtunex"));
  if (!user || user === "undefined" || user === null || user === "")
    return null;
  return user["token"];
};

export const registerUser = async (userRegister) => {


  try {
    const response = await apiClient.post("/Register", userRegister);
    return response.data;
  } catch (error) {
    // Retrieve the API error message if it exists, else use the default translation
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.registrationError");

    // Display the translated error message
      toast.error(translatedErrorMessage);
  //  throw translatedErrorMessage;
  }
};


export const loginUser = async (loginCredentials) => {
  try {
    const response = await apiClient.post("Login", loginCredentials);

    if (response.data.success === true) {
      // Serialize the data before saving it to localStorage
      localStorage.setItem("userVirtunex", JSON.stringify(response.data.data));
      return response.data;
    }
  } catch (error) {
    // Use a default error message if error.response.data.errorMessage is not available
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.loginError");

    // Display the translated error message
      toast.error(translatedErrorMessage);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem("userVirtunex");
};

export const resetPasswordRequest = async (email) => {
  try {
    const response = await apiClient.post("/request-password-reset",  email );
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.passwordResetRequestError");

    // Display the translated error message
    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

// Reset password
export const resetPassword = async (payload) => {
  try {
    const response = await apiClient.post("/reset-password", payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.passwordResetError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

// Change password
export const changePassword = async (payload) => {
  try {
    const response = await apiClient.put("change-password", payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.passwordChangeError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

// Change email
export const changeEmail = async (payload) => {
  try {
    const response = await apiClient.post("update-email", payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.emailChangeError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

// Confirm email change
export const confirmEmailChange = async (userId, newEmail, token) => {
  try {
    const response = await apiClient.get("confirm-email-change", {
      params: { userId, newEmail, token },
    });
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.emailConfirmationError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

// Confirm email asynchronously
export const confirmEmailAsync = async (payload) => {
  try {
    const response = await apiClient.post("confirm-email", payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.emailConfirmationAsyncError");

    toast.error(translatedErrorMessage);
  //  throw translatedErrorMessage;
  }
};

// Resend email confirmation
export const resendEmailConfirmation = async (email) => {
  try {
    const response = await apiClient.get(`resend-email-confirm/${email}`);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.emailResendError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem("userVirtunex"));
  if (!user || user === "undefined" || user === null || user === "")
    return null;
  return user;
};

export const updateUser = async (payload) => {
  try {
    const response = await apiClient.put("/User/update", payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.defaultErrorMessage");

    toast.error(translatedErrorMessage);
  //  throw translatedErrorMessage;
  }
}

// Fetch scan projects
export const getScanProjects = async () => {
  try {
    const response = await apiClient.get("/RealEstateScan");
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.scanProjectsFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

// Fetch scan project by ID
export const getScanProjectById = async (id) => {
  try {
    const response = await apiClient.get(`RealEstateScan/id/${id}`);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.scanProjectFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const getScanProjectByName = async (projectName) => {
  try {
    const response = await apiClient.get(`RealEstateScan/project-exist/${projectName}`);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.scanProjectFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};
// Post real estate scan data
export const postRealEstateScan = async (data) => {
  try {
    const response = await apiClient.post("/RealEstateScan", data);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.realEstateScanPostError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

// Delete real estate scan data
export const deleteRealEstateScan = async (projectId) => {
  try {
    const response = await apiClient.delete(`/RealEstateScan/project/id/${projectId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Post digital order
export const postDigitalOrder = async (realEstateDigital) => {
  try {
    const response = await apiClient.post("/RealEstateDigital", realEstateDigital);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.digitalOrderPostError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

// Fetch digital projects
export const getDigitalProjects = async () => {
  try {
    const response = await apiClient.get("RealEstateDigital");
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.digitalProjectsFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

// Fetch digital project by ID
export const getDigitalProjectById = async (id) => {
  try {
    const response = await apiClient.get(`RealEstateDigital/id/${id}`);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.digitalProjectFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const getDigitalProjectByName = async (projectName) => {
  try {
    const response = await apiClient.get(`RealEstateDigital/project-exist/${projectName}`);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.scanProjectFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

// Fetch digital projects by user ID
export const getDigitalProjectsByUserId = async (userId) => {
  try {
    const response = await apiClient.get(`RealEstateDigital/userid/${userId}`);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.digitalProjectsByUserFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

// Delete Digital project

export const deleteRealEstateDigital = async (projectId) => {
  try {
    const response = await apiClient.delete(`/RealEstateDigital/project/id/${projectId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add Revit file URN to digital project
export const addRevitFileUrn = async (projectId, payload) => {
  try {
    const response = await apiClient.put(`RealEstateDigital/${projectId}/add-urn`, payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.revitFileUrnAddError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const addShareLinkDigital = async (projectId, payload) => {
  try {
    const response = await apiClient.put(`/RealEstateDigital/${projectId}/share-link`, payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;

    toast.error(apiErrorMessage);
    throw apiErrorMessage;
  }
};

export const connectProjectsWithSubsDigital = async (susbcriptionId, payload) => {
  try {
    const response = await apiClient.put(`/RealEstateDigital/${susbcriptionId}/projects-subscription`, payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;

    toast.error(apiErrorMessage);
    throw apiErrorMessage;
  }
};

export const connectProjectsWithSubsScan = async (susbcriptionId, payload) => {
  try {
    const response = await apiClient.put(`/RealEstateScan/${susbcriptionId}/projects-subscription`, payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;

    toast.error(apiErrorMessage);
    throw apiErrorMessage;
  }
};


export const addScanPhotoUrl = async (projectId, payload) => {
  try {
    const project = await apiClient.put(
      `RealEstateScan/${projectId}/add-photoUrl`,
      payload
    );
    return project.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.scanPhotoUrlAddError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const addDigitalPhotoUrl = async (projectId, payload) => {
  try {
    const project = await apiClient.put(
      `RealEstateDigital/${projectId}/add-photoUrl`,
      payload
    );
    return project.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.digitalPhotoUrlAddError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const getScanProjectsByUserId = async (userId) => {
  try {
    const project = await apiClient.get(`RealEstateScan/userid/${userId}`);
    return project.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.scanProjectsByUserIdFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const scheduleDemo = async (payload) => {
  try {
    const response = await apiClient.post("User/schedule-demo", payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.demoScheduleError");

   // toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const candidateRequest = async (payload) => {
  try {
    const response = await apiClient.post("candidate", payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.candidateRequestError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const paymentCheckout = async (paymentRequest) => {
  try {
    const response = await apiClient.post(
      "Payment/create-checkout-session",
      paymentRequest
    );
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.paymentCheckoutError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const markProjectAsPaid = async (projectId, serviceType) => {
  try {
    const response = await apiClient.post(
      `Payment/project/id/${projectId}/type/${serviceType}`
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong.");
    throw error;
  }
};


export const checkPaymentStatus = async (projectId, retries = 10) => {
  try {
    const response = await apiClient.get(`/Payment/status/${projectId}`);

    if (response.data.data === "completed") {
      // Proceed with any action after payment is completed
    } else if (retries > 0) {
      setTimeout(() => checkPaymentStatus(projectId, retries - 1), 3000);
    } else {
     
    }
    return response;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.paymentStatusCheckError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  
  }
};

export const subscribCheckout = async (subsRequest) => {
  try {
    const response = await apiClient.post(
      "Payment/create-subs-session",
      subsRequest
    );
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.subscriptionCheckoutError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const getCustomerBillingPortal = async (customerId) => {
  try {
    const response = await apiClient.get(
      `Payment/customer-portal-billing/${customerId}`
    );
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.customerBillingPortalFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const getUserActiveSubscriptions = async () => {
  try {
    const userId = getCurrentUser()?.id;
    const response = await apiClient.get(
      `Payment/active-subscriptions/${userId}`
    );
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.userActiveSubscriptionsFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  
  }
};

export const notifyProjectConnectedWithSubscription = async (subs) => {
  try {
    const response = await apiClient.post(
      "Payment/notify-project-connected", subs
    );
    return response.data;
  } catch (error) {
    
  }
};

export const notifyProjectCompleted = async (payload) => {
  try {
    const response = await apiClient.post(
      "order-completed", payload
    );
    return response.data;
  } catch (error) {
    
  }
};

export const cancelProject = async (payload) => {
  try {
    const response = await apiClient.post("Payment/refound", payload);
    return response.data;
  } catch (error) {
    //const errorMessage = error.response?.data?.errorMessage || "An error occurred while canceling the project.";
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.digitalProjectsFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const cancelProjectAdmin = async (projectId, payload) => {
  try {
    const response = await apiClient.put(
      `User/cancel-project/${projectId}`,
      payload
    );
    return response.data;
  } catch (error) {
    // const errorMessage = error.response?.data?.errorMessage || "An error occurred while canceling the project.";
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.projectCancelError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const cancelProjectDigital = async (projectId, payload) => {
  try {
    const response = await apiClient.put(
      `RealEstateDigital/cancel-project/${projectId}`,
      payload
    );
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.digitalProjectCancelError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const addDigitalWorkLink = async (id, payload) => {
  try {
    const response = await apiClient.put(
      `RealEstateDigital/${id}/workLink`,
      payload
    );
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.digitalWorkLinkAddError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};


export const updateStatusDigital = async (id, payload) => {
  try {
    const response = await apiClient.put(
      `RealEstateDigital/${id}/status`,
      payload
    );
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.digitalStatusUpdateError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const addScanlWorkLink = async (id, payload) => {
  try {
    const response = await apiClient.put(
      `RealEstateScan/${id}/workLink`,
      payload
    );
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.scanWorkLinkAddError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const putScanViewerlink = async (id, payload) => {
  try {
    const response = await apiClient.put(
      `RealEstateScan/${id}/scanLink`,
      payload
    );
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.scanViewerLinkUpdateError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const updateStatusScan = async (id, payload) => {
  try {
    const response = await apiClient.put(
      `RealEstateScan/${id}/status`,
      payload
    );
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.scanStatusUpdateError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const getUsers = async () => {
  try {
    const users = await apiClient.get("User");
    return users.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.usersFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const getAdminsAsync = async () => {
  try {
    const users = await apiClient.get("User/get-admins");
    return users.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.adminsFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const createAdminsAsync = async (payload) => {
  try {
    const users = await apiClient.post("register-admin", payload);
    return users.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.adminCreationError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const manageAdminStatusAsync = async (payload) => {
  try {
      const users = await apiClient.put('User/manage-admin-status', payload)
  return users.data
}
  catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.adminStatusUpdateError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
}


//coupons api

export const createCoupon = async (payload) => {
  try {
    const response = await apiClient.post("Coupon", payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.couponCreationError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const getCoupons = async () => {
  try {
    const response = await apiClient.get("Coupon");
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.couponsFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const updateCouponStatus = async (payload) => {
  try {
    const response = await apiClient.put("Coupon", payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.couponStatusUpdateError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const checkCouponUsability = async (payload) => {
  try {
    const response = await apiClient.post("Coupon/validate", payload);
    return response?.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.couponUsabilityCheckError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const assignCouponToUser = async (payload) => {
  try {
    const response = await apiClient.post("Coupon/assign-coupon", payload);
    return response;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.couponAssignmentError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const getCouponByName = async (couponName) => {
  try {
    const response = await apiClient.get(`Coupon/name/${couponName}`);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.couponFetchByNameError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const deleteCoupon = async (id) => {
  try {
    const response = await apiClient.delete(`Coupon/${id}/remove`);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    toast.error(apiErrorMessage);

  }
};

export const getAllDisableDates = async () => {
  try {
    const response = await apiClient.get("Calendar");
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.disabledDatesFetchError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const disableDate = async (payload) => {
  try {
    const response = await apiClient.post("Calendar", payload);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.dateDisableError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const removeDisabledDate = async (payload) => {
  try {
    const response = await apiClient.delete("Calendar", { data: payload });
    return response.data;
  } catch (error) {
    const apiErrorMessage = error.response?.data?.errorMessage;
    const translatedErrorMessage = apiErrorMessage
      ? i18n.t(`apiException.${apiErrorMessage}`)
      : i18n.t("defaultErrorMessages.disabledDateRemoveError");

    toast.error(translatedErrorMessage);
    throw translatedErrorMessage;
  }
};

export const fetchUserRole = () => {
  const user = getCurrentUser();
  if (user === null) return null;
  const token = getCurrentUser()["token"];
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const roles =
        decodedToken[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      return roles;
    } catch (error) {
    }
    return null;
  }
};

export const getForgeToken = async (callback) => {
  try {
    const response = await apiClient.get("oauth/token");
    const { access_token, expires_in } = response.data;
  
    callback(access_token, expires_in);
  } catch (error) {
  //  console.error("Error fetching Forge token:", error.response ? error.response.data : error.message);
  }
};

