"use client";
import { useEffect, useState, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import { cn } from "../../lib/utils";

export default function TypingAnimation({
  text,
  duration = 100,
  className,
}) {
  const [displayedText, setDisplayedText] = useState("");
  const [i, setI] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  // Intersection Observer to check visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 } // Adjust as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  // Reset the typing effect when `text` changes
  useEffect(() => {
    setDisplayedText(""); // Clear the displayed text
    setI(0); // Reset index
  }, [text]);

  // Typing animation effect
  useEffect(() => {
    if (isVisible) {
      const typingEffect = setInterval(() => {
        if (i < text.length) {
          setDisplayedText((prev) => prev + text.charAt(i));
          setI((prev) => prev + 1);
        } else {
          clearInterval(typingEffect);
        }
      }, duration);

      return () => {
        clearInterval(typingEffect);
      };
    }
  }, [isVisible, i, duration, text]);

  return (
    <AnimatePresence mode="wait">
      <h1
        ref={containerRef}
        className={cn(className)}
      >
        {displayedText}
      </h1>
    </AnimatePresence>
  );
}
  