import React, { act, useContext, useState } from "react";
import styles from "../../../Styles/dashboard.module.css";
import Plus from "../../../assets/Icons/add.png";
import filtersDefault from "../../../Models/filtersDefault";
import { GlobalContext } from "../../../GlobalContext/GlobalContext";
import {
  extractScanTypes,
  filterByStatus,
  filterProject,
  getProjectSatistics,
} from "../../../Helpers/adjustProjectData";
import { getDigitalProjects, getScanProjects } from "../../../API/apiService";
import { extractTypes } from "../../../Helpers/adjustProjectData";
import upArrow from "../../../assets/Icons/up-arrow.png";
import downArrow from "../../../assets/Icons/arrowDown.png";
import { IconButton, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";

export default function CardInfo() {
  const [activeCard, setActiveCard] = useState("");
  const [filter, setFilters] = useState(filtersDefault);
  const [openFilters, setOpenFilters] = useState(true);
  const [loading, setLoading] = useState({
    apply:false,
    clear:false,
    card:false
  })

  const {
    activeService,
    digitalProjects,
    setDigitalProjects,
    setScanProjects,
    projectSatistics,
  } = useContext(GlobalContext);

  const updateFilters = (event, filterName) => {
    const value = event.target.value;
    setFilters((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  const CardInfo = (cardName, type, number) => {
    return (
      <div
        onClick={(e) => handleGeneralStatusFilter(e, cardName)}
        className={
          activeCard === cardName ? styles.cardInfoActive : styles.cardInfo
        }
      >
        <p className={styles.cardType}>{type}</p>
        <p className={styles.totalNumber}>{number}</p>
      </div>
    );
  };

  const statusBasedOnCard = {
    c1: "all",
    c2: "Draft",
    c3: "InProgress",
    c4: "Completed",
    c5: "Canceled",
  };
  const handleGeneralStatusFilter = async (e, cardName) => {
    e.preventDefault();
    const toastId = toast.loading('...Loading')
    let data = [];
    setActiveCard(cardName);
    const status = statusBasedOnCard[cardName];
    try {
      if (activeService === "scan") {
        data = await fetchScanProjects();
        setScanProjects(filterByStatus(data, status));
        return;
      } else if (activeService === "digital") {
        data = await fetchDigitalProjects();
        setDigitalProjects(filterByStatus(data, status));
      }
    } catch (error) {
      
    }finally{
      toast.dismiss(toastId)
    }
  };

  const fetchDigitalProjects = async () => {
    try {
      const response = await getDigitalProjects();
      const adjustedResponse = extractTypes(response.data);
      return adjustedResponse;
    } catch (error) {}
  };

  const fetchScanProjects = async () => {
    try {
      const response = await getScanProjects();
      const adjustedResponse = extractScanTypes(response.data);

      return adjustedResponse;
    } catch (error) {}
  };
  const handleApplyFilters = async (event) => {
    event.preventDefault();
    setLoading({
      apply:true,
      clear:false
    });
    if (activeService === "scan") {
      const initialProjectsScan = await fetchScanProjects();
      var filterResult = filterProject(initialProjectsScan, filter);
      setScanProjects(filterResult);
    } else if (activeService === "digital") {
      const initialProjectsDigital = await fetchDigitalProjects();
      var filterResult = filterProject(initialProjectsDigital, filter);
      setDigitalProjects(filterResult);
    }
    setLoading({
      apply:false,
      clear:false
    });
  };

  const handleClearFilters = async (event) => {
    event.preventDefault();
    setFilters(filtersDefault);
    setLoading({
      apply:false,
      clear:true
    });
    if (activeService === "scan") {
      const initialProjectsScan = await fetchScanProjects();
      setScanProjects(initialProjectsScan);
    } else if (activeService === "digital") {
      const initialProjectsDigital = await fetchDigitalProjects();
      setDigitalProjects(initialProjectsDigital);
    }
    setLoading({
      apply:false,
      clear:false
    });
  };

  return (
    <>
      <div className={styles.card_info_cont}>
        {CardInfo("c1", "All", projectSatistics.all)}
        {CardInfo("c2", "Draft", projectSatistics.draft)}
        {CardInfo("c3", "In Progress", projectSatistics.inProgress)}
        {CardInfo("c4", "Completed", projectSatistics.completed)}
        {CardInfo("c5", "Canceled", projectSatistics.canceled)}
      </div>

      <div>
        <div className={styles.manageFilters}>
          <h3 className={styles.filterBy}>{openFilters ? "Filter by:" : ""}</h3>
          <img
            className={styles.arrow}
            onClick={() => setOpenFilters(!openFilters)}
            src={openFilters ? downArrow : upArrow}
            alt="arrow-img"
          />
        </div>
        {openFilters && (
          <div className={styles.dateFilterCont}>
            <div className={styles.calendarCont}>
              <div className={styles.calnedarContent}>
                <label className={styles.label} htmlFor="startDate">
                  Start Date:
                </label>
                <input
                  id="startDate"
                  className={styles.calendar}
                  type="date"
                  placeholder="Start date:"
                  value={filter.startDate}
                  onChange={(e) => {
                    updateFilters(e, "startDate");
                  }}
                />
              </div>
              <div className={styles.calnedarContent}>
                <label className={styles.label} htmlFor="endDate">
                  End Date:
                </label>
                <input
                  id="endDate"
                  className={styles.calendar}
                  type="date"
                  placeholder="End date"
                  value={filter.endDate}
                  onChange={(e) => {
                    updateFilters(e, "endDate");
                  }}
                />
              </div>
            </div>

            <div className={styles.drpContainer}>
              <div>
                <label className={styles.labelDrp} htmlFor="options">
                  Choose an option:
                </label>
                <select
                  onChange={(e) => {
                    updateFilters(e, "status");
                  }}
                  value={filter.status}
                  className={styles.drpFilter}
                  id="options"
                  name="options"
                >
                  <option value="">--Select--</option>
                  <option value="Draft">Draft</option>
                  <option value="InProgress">In Progress</option>
                  <option value="Canceled">Canceled</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
              <div>
                <label className={styles.label} htmlFor="clientEmail">
                  Client Email:
                </label>
                <input
                  className={styles.drpFilter}
                  id="clientEmail"
                  type="text"
                  placeholder="Client Email"
                  value={filter.clientEmail}
                  onChange={(e) => {
                    updateFilters(e, "clientEmail");
                  }}
                />
              </div>
              <div>
                <label className={styles.label} htmlFor="couponName">
                  Coupon Code:
                </label>
                <input
                  className={styles.drpFilter}
                  id="couponName"
                  type="text"
                  placeholder="Coupon Code"
                  value={filter.couponName}
                  onChange={(e) => {
                    updateFilters(e, "couponName");
                  }}
                />
              </div>
            </div>
            <div>
              <label className={styles.label} htmlFor="projectName">
                Project Name:
              </label>
              <input
                className={styles.drpFilter}
                id="projectName"
                type="text"
                placeholder="Project Name"
                value={filter.projectName}
                onChange={(e) => {
                  updateFilters(e, "projectName");
                }}
              />
            </div>
            <div className={styles.buttonGroup}>
              {/* <button
                onClick={handleApplyFilters}
                className={styles.applyButton}
                type="button"
              >
                Apply Filters
              </button> */}
              <Button
              variant="contained"
              color="inherit"
              disabled={loading.apply}
              sx={{
                backgroundColor: "#1a477f",
                color: "white",
                padding: 1,
                textTransform: "none",
                width: 150,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                "&:hover": {
                  backgroundColor: "#122c51",
                },
              }}
              onClick={handleApplyFilters}
              startIcon={
                loading.apply && (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                
                )
              }
            >
              {loading.apply ? "...Filtering" : "Apply Filters"}
            </Button>
            <Button
              variant="outlined"
              disabled={loading.clear}
              color="error"
              sx={{
                padding: 1,
                textTransform: "none",
                width: 150,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
              onClick={handleClearFilters}
              startIcon={
                loading.clear && (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                )
              }
            >
              {loading.clear ? "...Clearing" : "Clear Filters"}
            </Button>
            </div>
          </div>
        )}
      </div>

      {/*  <>
       <div className={styles.filtersCont}>
        <div className={styles.filtersType}>
          <div
          onClick={() => handleFilterClick('dateTime')}
          >
            <img className={styles.plusIcon} src={Plus} alt="plus" />
            <p>Date and time</p>
          </div>
          <div
          onClick={() => handleFilterClick('status')}
          >
            <img className={styles.plusIcon} src={Plus} alt="plus" />
            <p>Status</p>
          </div>
          <div
          onClick={() => handleFilterClick('projectName')}
          >
            <img className={styles.plusIcon} src={Plus} alt="plus" />
            <p>Project Name</p>
          </div>
        </div>
        <div className={styles.clearFilters}>
          <p>Clear filters</p>
        </div>
        
      </div>
      {filter.dateTime && (
          <div className={styles.calendars}>
        
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker label="Select start date" />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer 
            components={["DatePicker"]}>
              <DatePicker 
            
              label="Select end date"
              InputLabelProps={{
                style: {  } // Custom style for label
              }} />
            </DemoContainer>
          </LocalizationProvider>
          <Button color="inherit" variant="contained"
          sx={{backgroundColor:'#1f4b82', color:'white', mt:1,justifyContent:'center'}}
          >Apply</Button>
        </div>
      )}
      {filter.status && (
         <div>
         <FormControl sx={{ m: 1, width: 300 }}>
           <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
           <Select
             labelId="demo-multiple-checkbox-label"
             id="demo-multiple-checkbox"
             multiple
             value={personName}
             onChange={handleChange}
             input={<OutlinedInput label="Status" />}
             renderValue={(selected) => selected.join(', ')}
             MenuProps={MenuProps}
           >
             {names.map((name) => (
               <MenuItem 
               sx={{ display: 'flex', alignItems: 'center' }} 
               key={name} value={name}>
                 <Checkbox checked={personName.indexOf(name) > -1} />
                 <ListItemText
                   sx={{ display: 'flex', alignItems: 'center' }} // Aligns the checkbox and text
                   primary={name}
                 />
               </MenuItem>
             ))}
           </Select>
         </FormControl>
       </div>
       
      )} 
      </>*/}
    </>
  );
}
