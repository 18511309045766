import React from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "../../assets/Icons/downloadIcon.png";
import ForgeViewer from "../AutoDeskForge/ForgeViewer";
import { Tooltip } from "@mui/material";

export default function Projects() {
  const { t } = useTranslation();

  return (
    <div className="projects-container">
      {/* <p className="gradient-text">
        <span>{t("projects.title")}</span>
      </p> */}
      <p className="gradient-text">
        <span>{t("projects.exampleTitle")}</span>
      </p>

      <div className="digital-projects">
        <div>
          <div className="download-projects">
            <h3>{t("projects.digitalizationExample")}</h3>

            <div>
              <p>.RVT file</p>
              <Tooltip title={t("projects.fileDownloads.rvt")}>
                <a
                  className="dl-icon-project"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t("projects.links.rvt")}
                >
                  <img src={DownloadIcon} alt="download-icon" />
                </a>
              </Tooltip>
              {/* <div className="tooltip-project">{t('projects.fileDownloads.rvt')}</div> */}
            </div>
            <div>
              <p>.IFC File</p>
              <Tooltip title={t("projects.fileDownloads.ifc")}>
                <a
                  className="dl-icon-project"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t("projects.links.ifc")}
                >
                  <img src={DownloadIcon} alt="download-icon" />
                </a>
              </Tooltip>
              {/* <div className="tooltip-project">{t('projects.fileDownloads.ifc')}</div> */}
            </div>
            <div>
              <p>.DWG File</p>
              <Tooltip title={t("projects.fileDownloads.dwg")}>
                <a
                  className="dl-icon-project"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t("projects.links.dwg")}
                >
                  <img src={DownloadIcon} alt="download-icon" />
                </a>
              </Tooltip>
              {/* <div className="tooltip-project">{t('projects.fileDownloads.dwg')}</div> */}
            </div>
            <div>
              <p>.RCS File</p>
              <Tooltip title={t("projects.fileDownloads.rcs")}>
                <a
                  className="dl-icon-project"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t("projects.links.rcs")}
                >
                  <img src={DownloadIcon} alt="download-icon" />
                </a>
              </Tooltip>

              {/* <div className="tooltip-project">{t('projects.fileDownloads.rcs')}</div> */}
            </div>
            <div>
              <p>.PNG File</p>
              <Tooltip title={t("projects.fileDownloads.jpeg")}>
                <a
                  className="dl-icon-project"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t("projects.links.jpeg")}
                >
                  <img src={DownloadIcon} alt="download-icon" />
                </a>
              </Tooltip>
              {/* <div className="tooltip-project">{t('projects.fileDownloads.jpeg')}</div> */}
            </div>
          </div>
          <div className="project-img">
            <ForgeViewer urn={import.meta.env.VITE_APP_PROJECT_URN} />
          </div>
        </div>
      </div>
      <br />
      <div className="digital-projects">
        <div>
          <div className="download-projects">
            <h3>{t("projects.scanningExample")}</h3>
            <div>
              <p>.e57 File</p>
              <Tooltip
              title= {t("projects.fileDownloads.xyz")}
              >
              <a
                className="dl-icon-project"
                target="_blank"
                rel="noopener noreferrer"
                href={t("projects.links.xyz")}
              >
                <img src={DownloadIcon} alt="download-icon" />
              </a>
               </Tooltip>
              {/* <div className="tooltip-project">
                {t("projects.fileDownloads.xyz")}
              </div> */}
            </div>
            <div>
              <p>.PNG File</p>
              <Tooltip title={t("projects.fileDownloads.img")}>
              <a
                className="dl-icon-project"
                target="_blank"
                rel="noopener noreferrer"
                href={t("projects.links.img")}
              >
                <img src={DownloadIcon} alt="download-icon" />
              </a>
               </Tooltip>
              {/* <div className="tooltip-project">
                {t("projects.fileDownloads.img")}
              </div> */}
            </div>
          </div>
          <div className="project-img frame-tour">
            <iframe
              src="https://realsee.ai/m9aaPxVP"
              width="100%"
              allow="fullscreen; autoplay=0"
              style={{ border: "none" }}
            ></iframe>

            {/* <a 
              target="_blank" rel="noopener noreferrer"
              href={t('projects.links.scan')}
            >
              <img src={ScanImg} alt="project-video" />
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
}
