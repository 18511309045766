import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DigitalOrderImg from "../../assets/Images/digitalOrder.png";
import {
  getDigitalProjectsByUserId,
  paymentCheckout,
  getCurrentUser, 
  cancelProject, 
} from "../../API/apiService";
import ValidatedTextField from "../Services/ValidateTextField";
import { Tooltip,
  Modal, Box, Typography, Button,CircularProgress
   } from "@mui/material";
   import {
    requiredValidator,
  } from "../../Validators/validator";
import { Link, useNavigate } from "react-router-dom";
import NoOrders from "./NoOrders";
import toast from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px", // Add this line for border radius
  boxShadow: 24,
  p: 4,
  
};

export default function OrderDigital() {
  const { t } = useTranslation(); // Translation hook

  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderCancel, setOrderCancel] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [refreshLink, setRefreshLink] = useState("")
  const handleClosePayProjectModal = () => setOpenPayProjectModal(false);
  const [openPayProjectModal, setOpenPayProjectModal] = useState(false)
  const [paymentInProcess, setPaymentInProcess] = useState(false)
  const [payAction, setPayAction] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (getCurrentUser() === null) {
      navigate("/login");
      return;
    }

    const projectsDigital = async () => {
      try {
        setIsLoading(true);
        const result = await getDigitalProjectsByUserId(
          getCurrentUser()["id"]
        );
        if (result.success === true) {
       
          setOrders(result);
        }
      } catch (error) {
        setIsLoading(false);
        navigate("/server-error");
      } finally {
        setIsLoading(false);
      }
    };

    projectsDigital();
    
  }, [orderCancel]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      navigate("/request-successful?type=payment");
    }

    if (query.get("canceled")) {
      // toast.error("Something went wrong. Please try again later.");
    }
  }, []);
     

  const displayUnit = (unit) => {
    if (unit === "imperial") {
      return t("orderDigital.unitImperial");
    }
    return t("orderDigital.unitMetric");
  };

  const cancelProjectAsync = async (order) => {
    try {
      const payload = {
        projectId: order.projectId,
        serviceType: "Digital",
      };
      const response = await cancelProject(payload);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOrderCancel(!orderCancel);
    }
  };

  const handleCancelproject = async (event, order) => {
    event.stopPropagation();
    event.preventDefault();
    if (order.status === "Draft") {
      const response = cancelProjectAsync(order);
      toast.promise(
        response,
        {
          loading: t("orderDigital.loading"),
          success: <b>{t("orderDigital.success")}</b>,
          error: <b>{t("orderDigital.error")}</b>,
        },
        {
          success: { duration: 6000 },
        }
      );
    } else {
      toast.error(t("orderDigital.onlyDraftCancellation"));
    }
  };

  const handleOpenModalProject = (event, order, isPayment) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenPayProjectModal(true)
    setSelectedProject(order)
    setPayAction(isPayment);
  }

  const handlePayProject = async () => {
    try {
      setPaymentInProcess(true)
      const paymentPath = await paymentCheckout({
        projectId: selectedProject.projectId,
        totalPrice: selectedProject?.payment?.totalPrice,
        serviceType: "digital",
      });
   
      window.location.href = paymentPath.sessionUrl;
    } catch (error) {
      
    }finally{
      setPaymentInProcess(false)
    }
  
  }

  const handleRefreshProject = async () => {
    try {
      if(refreshLink === ""){
        toast.error(t('textValidation.validProjectUrlRequired'))
        return;
      }
      setPaymentInProcess(true);
      const paymentPath = await paymentCheckout({
        projectId: selectedProject.projectId,
        totalPrice: getPriceWithDiscount(selectedProject.payment.totalPrice).toFixed(2).toString(),
        serviceType: "digital",
        refreshLink: refreshLink
      });

      window.location.href = paymentPath.sessionUrl;
    } catch (error) {
    } finally {
      setPaymentInProcess(false);
    }
  };

  const handleProjectEvent = () => {
    if(payAction){
      handlePayProject();
    }
    else{
      handleRefreshProject();
    }
  }


  const getPriceWithDiscount = (price) => {
    const priceNew = price - (price * 40)/100;
    return priceNew.toFixed(2);
  }


  return orders?.data?.length === 0 ? (
    <div className="no-order">
    <NoOrders redirectTo="/services/digital" />
  </div>
  ) : (
    <div className="order-container">
      {isLoading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        orders?.data?.map((order) => (
          <Link
            className="link"
            key={order.projectId}
            to={`/orders/digital/${order.projectId}`}
          >
            <div key={order.projectId} className="order-card-container">
              <div className="order-card-info">
                <img
                  className="img-project"
                  src={order?.photoUrl || DigitalOrderImg}
                  alt="projectPhoto"
                />

                <p style={{alignSelf:'start'}} >{order.projectName}</p>
                <div className="cancel-order">
                  <div style={ order.status === "Draft" ? { width:'80%'} : { width:'100%'}}>
                    <p className="order-address">{order.propertyInfoDigital.address}</p>
                  </div>   
                  {order.status === "Draft" && (
                    <div>
                      {/* <p
                        onClick={(event) => handleCancelproject(event, order)}
                        className="refound-btn"
                      >
                        {t("orderDigital.cancelButton")}
                      </p>
                      <div className="tooltip">
                        {t("orderDigital.cancelTooltip")}
                      </div> */} 
                        <p
                          onClick={(event) => handleOpenModalProject(event, order, true)}
                          className="pay-btn"
                        >
                          {t("orderScan.payButton")}
                        </p>
                    </div>
                  )}
                  {order.status === "Completed" && (
                    <div>
                      <p
                        onClick={(event) =>
                        handleOpenModalProject(event, order, false)
                        }
                        className="pay-btn"
                      >
                        {t("common.refreshBtn")}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="card-param-cont">
                <p className="order-param param-label">
                  {t("orderDigital.propertyTypeLabel")}
                </p>
                <p className="order-param param-label">
                  {t("orderDigital.spaceSiteSizeLabel")}
                </p>
                <p className="order-param param-label">
                  {t("orderDigital.statusLabel")}
                </p>
                <p className="order-param param-info param-info1">
                {order.propertyInfoDigital.propertyType[0].type === "Standard"
                  ? t("orderDetailsDigital.spaceTypeStandard")
                  : order.propertyInfoDigital.propertyType[0].type === "Residencial"
                  ? t("orderDetailsScan.residentialType")
                  : order.propertyInfoDigital.propertyType[0].type === "Industrial"
                  ? t("orderDetailsScan.industrialType")
                  : order.propertyInfoDigital.propertyType[0].type === "Commercial"
                  ? t("orderDetailsScan.commercialType")
                  : order.propertyInfoDigital.propertyType[0].type === "Institutional"
                  ? t("orderDetailsScan.institutionalType")
                  : null}
                </p>
                <p className="order-param param-info param-info2">
                  {order.propertyInfoDigital.propertySize}/
                  {order.propertyInfoDigital.landSize}
                  {displayUnit(order.propertyInfoDigital.unit)}
                </p>

                <p
                  className={`order-param param-info param-info3 status-${order.status}`}
                >
                {order.status === "InProgress" 
                  ? t('status.progress')
                  : order.status === "Draft" 
                  ? t('status.draft')
                  : order.status === "Canceled" 
                  ? t('status.canceled')
                  : order.status === "Completed" 
                  ? t('status.completed')
                  : order.status === "Refreshed"
                  ? t("status.refreshed")
                  : null
                }
                </p>
              </div>
            </div>
          </Link>
        ))
      )}
        <Modal
        open={openPayProjectModal}
        onClose={handleClosePayProjectModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
        {payAction && (
            <>
              <Typography id="modal-title" variant="h6" component="h2">
                {t("common.continuePayment")}
              </Typography>
              <Typography
                sx={{
                  color: "grey",
                }}
              >
                {t("common.projectName")} <strong>{selectedProject.projectName}</strong> 
                <br />
                {t("common.priceToPay")} <strong>{selectedProject.payment?.totalPrice} €</strong>
              </Typography>
            </>
          )}
          {!payAction && (
            <>
              <Typography id="modal-title" variant="h6" component="h2">
                {t("common.continueRefreshing")}
              </Typography>
            <div style={{marginTop:'10px'}}>
            <ValidatedTextField
              label={t("digitalService.uploadFilesLabel")}
              type="text"
              tooltip={t("digitalService.uploadFilesTooltip")}
              widthPass={310}
              validator={requiredValidator}
              onChange={(e) => setRefreshLink(e.target.value)}
            />
          </div>
              <Typography
                sx={{
                  color: "grey",
                  fontSize:15,
                  mt:1,
                  fontWeight:400
                }}
              >
                {t("common.projectName")} <strong>{selectedProject.projectName}</strong> 
                <br />
                {t("common.previousCost")} <s>{selectedProject.payment?.totalPrice} €</s>
                <br />
                {t("common.priceToPay")} <strong>{getPriceWithDiscount(selectedProject.payment?.totalPrice)} €</strong>
              </Typography>
              <Typography
              sx={{
                mt:1,
                color: "#b0b0b0",
                fontSize: "13px",
                fontStyle:'italic'
              }}
            >
                 {t("common.noteDigitalRefresh")}
              </Typography>
            </>
          )}
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleProjectEvent}
              sx={{ mr: 1 }}
              disabled={paymentInProcess}
              startIcon={paymentInProcess ? <CircularProgress size={24} /> : null} 
            >
               {paymentInProcess ? t("priceScan.processing") : t("digitalService.yes")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClosePayProjectModal}
            >
              {t('digitalService.no')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
