import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  Chip,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getScanProjectsByUserId,
  getCurrentUser,
  getUserActiveSubscriptions,
  connectProjectsWithSubsScan,
  notifyProjectConnectedWithSubscription,
} from "../../API/apiService";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SubscriptionOptions from "./SubscriptionOptions";

const theme = createTheme({
  palette: {
    primary: {
      main: "#163D6C",
    },
  },
});

const ChooseSubsPlanScan = () => {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingEvent, setLoadingEvent] = useState(false);
  const [modified, setModified] = useState(false);
  const [activeSubscription, setActiveSubscriptions] = useState({});
  const [projectsWithoutSubscription, setProjectsWithoutSubscription] =
    useState([]);
  const [projectsWithSubscription, setProjectsWithSubscription] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (getCurrentUser() === null) {
      navigate("/login");
      return;
    }

    const projectsScan = async () => {
      try {
        setLoading(true);
        const result = await getScanProjectsByUserId(getCurrentUser()["id"]);
        const activeSubscriptions = await getUserActiveSubscriptions();
        const scanSubs = activeSubscriptions.data?.filter(
          (s) => s.type === "scan" && s.isActive === true
        );

        setActiveSubscriptions(scanSubs || []);

        if (result.success === true) {
          const projectsOutOfSubscription = result.data?.filter(
            (p) =>
              p.subscriptionId === null &&
              (p.status.toLowerCase() === "completed" ||
                p.status.toLowerCase() === "refreshed")
          );
          const projectsWithSubscription = result.data?.filter(
            (p) => p.subscriptionId !== null
          );

          setProjectsWithoutSubscription(projectsOutOfSubscription || []);
          setProjectsWithSubscription(projectsWithSubscription || []);
        }
      } catch (error) {
        navigate("/server-error");
      } finally {
        setLoading(false);
      }
    };

    projectsScan();
  }, [modified]);

  const handleProjectChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProjects(value);
  };

  const getMaxNrOfProjectsByPlanName = (planName) => {
    if (planName === "s1") {
      return 3;
    } else if (planName === "s2") {
      return 10;
    } else if (planName === "s3") {
      return 20;
    } else if (planName === "s4") {
      return 40;
    }
  };

  const handleConnectProjectWithSubscription = async () => {
    if (selectedProjects.length === 0) {
      toast.error(t("subscription.selectProject"));
      return;
    }
    if (checkIfProjectCanConnectedWithSubscription() === false) {
      toast.error(t("subscription.canNotConnectMessage"));

      return;
    }

    if (activeSubscription && selectedProjects.length > 0) {
      try {
        setLoadingEvent(true);
        const response = await connectProjectsWithSubsScan(
          activeSubscription[0].id,
          selectedProjects
        );
        if (response.success) {
          const subsDto = {
            projectIds: selectedProjects,
            userId: getCurrentUser()["id"],
            serviceType: "scan",
            planType: activeSubscription[0].planType,
          };
          await notifyProjectConnectedWithSubscription(subsDto);
          toast.success(t("subscription.projectConnected"));
        }
      } catch (error) {
      } finally {
        setSelectedProjects([]);
        setLoadingEvent(false);
        setModified(!modified);
      }
    }
  };

  const checkIfProjectCanConnectedWithSubscription = () => {
    const maxNrOfProjectsOfferedByPlan = getMaxNrOfProjectsByPlanName(
      activeSubscription[0].planType
    );

    const projectsConnectedWithSubscription =
      projectsWithSubscription.length + selectedProjects.length;

    if (projectsConnectedWithSubscription > maxNrOfProjectsOfferedByPlan) {
      return false;
    }

    return true;
  };

  if (loading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: "2rem", minHeight: "100vh", mt: 1 }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontSize: { xs: "18px", md: "24px" } }}
        >
          {t("subscription.includeProjectInSubsPlan")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "40px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <FormControl sx={{ width: { xs: "100%", md: "30%" } }}>
              <InputLabel id="project-select-label">
                {t("subscription.yourProjects")}
              </InputLabel>
              <Select
                labelId="project-select-label"
                multiple
                disabled={
                  activeSubscription.length === 0 ||
                  projectsWithoutSubscription.length === 0
                }
                value={selectedProjects}
                onChange={handleProjectChange}
                input={<OutlinedInput label={t("subscription.yourProjects")} />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          projectsWithoutSubscription.find(
                            (proj) => proj.projectId === value
                          )?.projectName
                        }
                      />
                    ))}
                  </Box>
                )}
              >
                {projectsWithoutSubscription.map((project) => (
                  <MenuItem key={project.projectId} value={project.projectId}>
                    {project.projectName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {activeSubscription?.length > 0 && (
              <Button
                onClick={() => handleConnectProjectWithSubscription()}
                variant="outlined"
                color="primary"
                sx={{ textTransform: "none", maxHeight: 55, minWidth: 80 }}
                disabled={selectedProjects.length === 0 || loadingEvent}
                startIcon={
                  loadingEvent && (
                    <CircularProgress size={24} sx={{ color: "#163d6c" }} />
                  )
                }
              >
                {loadingEvent
                  ? t("priceScan.processing")
                  : t("subscription.addProjects")}
              </Button>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 20,
            mt: 10,
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          {projectsWithSubscription?.length > 0 && (
            <Box>
              <Typography sx={{ mb: 1 }}>
                {t("subscription.includedProjects")}
              </Typography>
              <TableContainer
                component={Paper}
                style={{ maxHeight: 400, minWidth: 200, overflowY: "auto" }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>{t("subscription.projectName")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectsWithSubscription.map((project, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{project.projectName}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          {activeSubscription?.isActive &&
            projectsWithSubscription?.length === 0 && (
              <Box>
                <Typography sx={{ mb: 1 }}>
                  {t("subscription.includedProjects")}
                </Typography>
                <Typography sx={{ fontSize: 13, color: "grey" }}>
                  <TableCell>{t("subscription.noProjectIncluded")}</TableCell>
                </Typography>
              </Box>
            )}
          {activeSubscription?.length === 0 &&
            projectsWithSubscription?.length === 0 && (
              <Box>
                <Typography sx={{ mb: 1, fontSize: 16 }}>
                  {t("subscription.noScanPlanActive")}
                </Typography>
              </Box>
            )}
        </Box>
        <SubscriptionOptions serviceType="scan" />
      </Box>
    </ThemeProvider>
  );
};

export default ChooseSubsPlanScan;
