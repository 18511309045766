import React, { useState, useEffect } from "react";
import InfoIcon from "../assets/Icons/InfoIcon.png";
import calcImage from "../assets/Images/calcImage.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  getRegionTypePrice,
  getPiceBasedOnPropertyAndScanType,
  getTotalPrice,
  getPriceBasedOnSiteSize,
} from "../Helpers/helpers";
import {
  postRealEstateScan,
  checkCouponUsability,
  getCouponByName,
  assignCouponToUser,
  fetchUserRole,
  getScanProjectByName,
} from "../API/apiService";
import { validateFields } from "../Validators/validator";
import toast from "react-hot-toast";
import { getCurrentUser } from "../API/apiService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@mui/material";
import { IconButton, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DiscountIcon from "@mui/icons-material/Discount";

const PricingCard = ({ realEstateScan }) => {
  const ScanServiceModel = realEstateScan;
  const [totalPrice, setTotalPrice] = useState(0.0);
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [couponData, setCouponData] = useState({});
  const [loading, setLoading] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false)
  const [couponInfo, setCouponInfo] = useState({
    isCouponApplied: false,
    originalPrice: 0,
    discountPrice: 0,
  });
  const region = realEstateScan.PropertyInfoScan.Address?.City || null;
  const spaceSize = realEstateScan.PropertyInfoScan.SizeOfSpace ?? 0;
  const siteSize = realEstateScan.PropertyInfoScan.SizeOfSite ?? 0;
  const propType = realEstateScan.PropertyInfoScan.PropertyType[0] ?? null;
  const scanType = realEstateScan.PropertyInfoScan.ScanningType ?? null;
  const googleMap = realEstateScan.GoogleMap;
  const scanFile = realEstateScan.ScanFile;
  const { t } = useTranslation();
  const {name, couponPercentage} = couponData;

  useEffect(() => {
    fetchUserRole();
    if (region && spaceSize > 0 && propType !== null && scanType.length > 0) {
      const total = getTotalPrice(
        getRegionTypePrice(region),
        getPiceBasedOnPropertyAndScanType(spaceSize, propType, scanType),
        getPriceBasedOnSiteSize(siteSize, propType?.name),
        scanFile,
        googleMap
      );
      setTotalPrice((total + 14).toFixed(2));
      if (couponInfo.isCouponApplied) {
        priceChangesBasedOnCoupon(total.toFixed(2) + 14);
      }
    } else {
      setTotalPrice(0.0);
    }
  }, [region, spaceSize, siteSize, propType, scanType, googleMap, scanFile]);

  // useEffect(() => {
  //   const query = new URLSearchParams(window.location.search);

  //   if (query.get("success")) {
  //     var responseOrder = scanOrder(); // Now this runs only after polling is done

  //     toast
  //       .promise(responseOrder, {
  //         loading: t("order.processing"),
  //         success: t("order.success"),
  //         error: t("order.error"),
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }

  //   if (query.get("canceled")) {
  //     localStorage.removeItem("scanOrder");
  //     // toast.error("Something went wrong. Please try again later.");
  //   }
  // }, []);

  // function useQuery() {
  //   return new URLSearchParams(useLocation().search);
  // }
  // const query = useQuery();
  // const projectId = query.get("id");
  // const success = query.get('success');
  // // Now you can use the paymentIntentId in your component logic

  ///TODO change the static attribute for scanning in the future
  const payload = {
    // If the project ID is different, adjust accordingly
    projectId: "",
    businessName: ScanServiceModel.BusinessName,
    emailBusiness: ScanServiceModel.EmailBusiness,
    projectName: ScanServiceModel.ProjectName,
    scanFile: ScanServiceModel.ScanFile,
    googleMap: ScanServiceModel.GoogleMap,
    userId: getCurrentUser() !== null ? getCurrentUser()["id"] : "",
    bookingMeeting: ScanServiceModel.BookingMeeting,
    status: ScanServiceModel.Status,
    couponId: "",
    couponName: "",
    propertyInfoScan: {
      ownerName: ScanServiceModel.PropertyInfoScan.OwnerName,
      objectPhoto: ScanServiceModel.PropertyInfoScan.ObjectPhoto,
      sizeOfSpace: ScanServiceModel.PropertyInfoScan.SizeOfSpace,
      sizeOfSite: ScanServiceModel.PropertyInfoScan.SizeOfSite,
      cadastralRegion: ScanServiceModel.PropertyInfoScan.CadastralRegion,
      propertyNumber: ScanServiceModel.PropertyInfoScan.PropertyNumber,
      volume: ScanServiceModel.PropertyInfoScan.Volume,
      page: ScanServiceModel.PropertyInfoScan.Page,
      nomenclatureMap: ScanServiceModel.PropertyInfoScan.NomenclatureMap,
      description: ScanServiceModel.PropertyInfoScan.Description,
      referencePrice: ScanServiceModel.PropertyInfoScan.ReferencePrice,
      address: {
        country: ScanServiceModel.PropertyInfoScan.Address.Country,
        region: ScanServiceModel.PropertyInfoScan.Address.City,
        addressLine: ScanServiceModel.PropertyInfoScan.Address.AddressLine,
        googleMapLocation:
          ScanServiceModel.PropertyInfoScan.Address.GoogleMapLocation,
      },
      scanningType: ScanServiceModel.PropertyInfoScan.ScanningType.map(
        (type) => ({
          id: type.id,
          type: type.name,
        })
      ),

      propertyType: ScanServiceModel.PropertyInfoScan.PropertyType.map(
        (type) => ({
          id: type.id,
          type: type.name,
        })
      ),
    },
    siteContact: {
      name: ScanServiceModel.SiteContact.Name,
      email: ScanServiceModel.SiteContact.Email,
      phoneNumber: ScanServiceModel.SiteContact.PhoneNumber,
    },
    

    totalPrice: totalPrice,
  };

  // const scanOrder = async () => {
  //   const scanModel = localStorage.getItem("scanOrder");
  //   const parsedModel = JSON.parse(scanModel);

  //   if (parsedModel && parsedModel.couponName !== "") {
  //      await userUseCoupon(parsedModel.couponName);
  //   }

  //   parsedModel.projectId = projectId;
  //   try {
  //     const response = await postRealEstateScan(parsedModel);
  //     if (response && response.success) {
  //       navigate("/request-successful");
  //     }
  //     return response;
  //   } catch (error) {
  //     throw error;
  //   } finally {
  //     localStorage.removeItem("scanOrder");
  //     setLoading(false)
  //   }
  // };

  const userUseCoupon = async (couponName) => {
    var couponDto = await getCouponByName(couponName);

    const useCouponDto = {
      userId: getCurrentUser()["id"],
      couponId: couponDto.id,
    };
    // Assign the coupon to the user
    await assignCouponToUser(useCouponDto);
  };

  const checkIfCouponCanBeUsed = async () => {
    const payload = {
      userId: getCurrentUser()["id"],
      couponName: coupon,
    };

    try {
      // Call API to check if the coupon can be used
      var response = await checkCouponUsability(payload);

      // If the response indicates that the coupon can be used
      if (response.success) {
        return true; // Successfully assigned the coupon
      }
      return false; // Coupon cannot be used
    } catch (error) {
      return false;
    }
  };

  const priceChangesBasedOnCoupon = (originalPrice) => {
    var discountPrice =
      originalPrice - originalPrice * (couponData.couponPercentage / 100);
    setCouponInfo((prevState) => ({
      ...prevState,
      isCouponApplied: true,
      originalPrice: originalPrice,
      discountPrice: discountPrice.toFixed(2),
    }));
  };

  const handleCouponApplication = async () => {
    if (!!coupon === false) {
      toast.error(t("common.couponRequired"));
      return;
    }
    try {
      setCouponLoading(true);
      var canUseCoupon = await checkIfCouponCanBeUsed();

      if (!canUseCoupon) return false;
      var couponDto = await getCouponByName(coupon);
  
      if (couponDto?.category !== "scan") {
        toast.error(t("common.couponIncorrectCategory"));
        return;
      }
  
      setCouponData(couponDto);
      var discountPrice =
        totalPrice - totalPrice * (couponDto.couponPercentage / 100);
      setCouponInfo((prevState) => ({
        ...prevState,
        isCouponApplied: true,
        originalPrice: totalPrice,
        discountPrice: discountPrice.toFixed(2),
      }));
    } catch (error) {
      
    }finally{
      setCouponLoading(false);
    }
   
  };

  const handleRemoveCoupon = () => {
    setCouponInfo({
      isCouponApplied: false,
      originalPrice: 0,
      discountPrice: 0,
    });
    setCouponData({});
    setCoupon("");
  };

  const handlePostRealEstateScan = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (getCurrentUser() === null) {
      setLoading(false);
      navigate("/login");
      return;
    }
    const fieldErrors = validateFields(payload);
    setErrors(fieldErrors);

    if (fieldErrors.length > 0) {
      const formattedMessage = fieldErrors.join("\n");
      // Display the toast notification with formatted message
      toast.error(formattedMessage, { duration: 8000 });
      //  toast.error("Please enter valid data on all required fields.");
      setLoading(false);
    } else {
      try {
        if (couponInfo.isCouponApplied) {
          payload.couponId = couponData.id;
          payload.totalPrice = couponInfo.discountPrice;
          payload.couponName = couponData.name
        }

        const projectExits = await getScanProjectByName(payload.projectName);
        
        if (!projectExits?.success) return;
        const response = await postRealEstateScan(payload);
        if (response && response.success) {
          navigate("/request-successful?type=order");
        }
        return response;
        // localStorage.setItem("scanOrder", JSON.stringify(payload));

        // const paymentPath = await paymentCheckout({
        //   totalPrice: newPrice,
        //   serviceType: "scan",
        // });
        // setLoading(false);
        // window.location.href = paymentPath.sessionUrl;
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="price-container">
        <div className="card-container">
          <h3 className="card-title">{t("priceScan.totalAmount")}</h3>
          {couponInfo.isCouponApplied ? (
            <>
              <p className="price ca">
                Original price:
                <span className="coupon-applied">
                  {" "}
                  € {couponInfo.originalPrice}
                </span>
              </p>
              <p className="price">
                Discount price: € {couponInfo.discountPrice}
              </p>
            </>
          ) : (
            <p className="price">€ {totalPrice }</p>

          )}

          <Button
            variant="contained"
            color="inherit"
            sx={{
              backgroundColor: "#1a477f",
              color: "white",
              padding: 1,
              textTransform: "none",
              width: 150,
              "&:hover": {
                backgroundColor: "#122c51",
              },
            }}
            onClick={handlePostRealEstateScan}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={24} /> : null}
          >
            {loading ? t("priceScan.processing") : t("priceScan.bookNow")}
          </Button>
        </div>
        <div className="img-price-container">
          <img
            className="imgCalc"
            src={calcImage}
            alt={t("priceScan.imgAlt")}
          />
        </div>
        {couponInfo.isCouponApplied === false ? (
          <div className="card-container">
            <h3 className="card-title">{t("priceScan.haveCoupon")}</h3>
            <TextField
              label={`${t("priceScan.enterCouponCode")} ${t(
                "priceScan.optional"
              )}`}
              variant="outlined"
              size="small"
              onChange={(e) => setCoupon(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  fontSize: "12px",
                  padding: "6px",
                },
                width: "220px",
              }}
              InputProps={{
                fontSize: "12px",
                padding: "14px",
                endAdornment: (
                  <Tooltip
                    title={t("priceScan.discountTooltip")}
                    arrow
                    PopperProps={{
                      disablePortal: true,
                      style: { marginTop: "8px" },
                    }}
                  >
                    <IconButton>
                      <img
                        style={{ width: "15px" }}
                        src={InfoIcon}
                        alt="info-icon"
                      />
                    </IconButton>
                  </Tooltip>
                ),
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "11px",
                  fontWeight: "600",
                  fontFamily: "Roboto, sans-serif",
                  padding: "8px",
                  marginLeft: "-3px",
                  shrink: true,
                },
              }}
              style={{ marginBottom: -10, fontSize: "12px" }}
            />
            <Button
              variant="contained"
              color="inherit"
              disabled={couponLoading}
              sx={{
                backgroundColor: "#1a477f",
                color: "white",
                padding: 1,
                textTransform: "none",
                width: 150,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                "&:hover": {
                  backgroundColor: "#122c51",
                },
              }}
              onClick={handleCouponApplication}
              startIcon={
                couponLoading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  <DiscountIcon
                    size={24}
                    sx={{ color: "white", mb: -0.1, mr: -1 }}
                  />
                )
              }
            >
              {couponLoading ? t("priceScan.processing") : t("common.applyDiscount")}
            </Button>
          </div>
        ) : (
          <div className="card-container-coupon">
            <div style={{ alignSelf: "center" }}>
              <CheckCircleIcon style={{ fontSize: "5rem", color: "#4caf50" }} />
            </div>

            <p>
              {t("common.couponApplied", {name, couponPercentage})}
              {/* Coupon <strong>{couponData.name}</strong> with{" "}
              <strong>{couponData.couponPercentage}% </strong>discount is
              applied. */}
            </p>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <p style={{ color: "grey", fontSize: "14px" }}>
                <u>{t("common.removeCoupon")}</u>
              </p>
              <Tooltip title="Remove">
                <CancelIcon
                  onClick={handleRemoveCoupon}
                  style={{
                    fontSize: "1.8rem",
                    color: "red",
                    marginBottom: "-0px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingCard;
