export const extractTypes = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((item) => {
    const submissionTime = item.submissionTime;
    const timeFormat = formatDateTime(submissionTime);
    const { propertyType, interiorScope, exteriorScope } =
      item.propertyInfoDigital;

    const propertyTypeString =
      propertyType.length > 0
        ? propertyType.map((pt) => pt.type).join(", ")
        : "";
    const interiorScopeString =
      interiorScope.length > 0
        ? interiorScope.map((is) => is.type).join(", ")
        : "";
    const exteriorScopeString =
      exteriorScope.length > 0
        ? exteriorScope.map((es) => es.type).join(", ")
        : "";

    return {
      ...item,
      submissionTime: timeFormat,
      propertyInfoDigital: {
        ...item.propertyInfoDigital,
        propertyType: propertyTypeString,
        interiorScope: interiorScopeString,
        exteriorScope: exteriorScopeString,
      },
    };
  });
};

export const extractScanTypes = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((item) => {
    const submissionTime = item.submissionTime;
    const bookingTime = item.bookingMeeting;
    const timeFormat = formatDateTime(submissionTime);
    // const bookingFormat = formatDateTime(bookingTime)

    const { scanningType, propertyType } = item.propertyInfoScan;

    const propertyTypeString =
      propertyType.length > 0
        ? propertyType.map((pt) => pt.type).join(", ")
        : "";
    const scanningTypeString =
      scanningType.length > 0
        ? scanningType.map((is) => is.type).join(", ")
        : "";

    return {
      ...item,
      submissionTime: timeFormat,
      propertyInfoScan: {
        ...item.propertyInfoScan,
        propertyType: propertyTypeString,
        scanningType: scanningTypeString,
      },
    };
  });
};

export function formatDate(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

function formatDateTime(inputDate) {
  // Create a new Date object from the input string
  const date = new Date(inputDate);

  // Extract year, month, day, hours, and minutes
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Construct and return the formatted date string
  return `${year}/${month}/${day} ${hours}:${minutes}`;
}
function toDate(dateString) {
  return new Date(dateString);
}

function filterByDateRange(filterResult, startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  return filterResult.filter((p) => {
    const submissionDate = toDate(p.submissionTime);
    return submissionDate >= start && submissionDate <= end;
  });
}

export const filterProject = (data, filters) => {
  let filterResult = data;

  // Filter by client Email if provided
  if (filters.clientEmail) {
    filterResult = filterResult.filter(
      (p) => p.clientEmail === filters.clientEmail
    );
  }

  // Filter by project name if provided
  if (filters.projectName) {
    filterResult = filterResult.filter(
      (p) => p.projectName === filters.projectName
    );
  }

    // Filter by couponName code if provided
    if (filters.couponName) {
      filterResult = filterResult.filter(
        (p) => p.couponName === filters.couponName
      );
    }

  // Filter by status if provided
  if (filters.status) {
    filterResult = filterResult.filter((p) => p.status === filters.status);
  }

  // Filter by date range if both startDate and endDate are provided
  if (filters.startDate && filters.endDate) {
    filterResult = filterByDateRange(
      filterResult,
      filters.startDate,
      filters.endDate
    );
  }

  return filterResult;
};

export const getProjectSatistics = (data) => {
  let satistics = {
    all: 0,
    draft: 0,
    inProgress: 0,
    completed: 0,
    canceled: 0,
  };

  satistics.all = data.length;
  satistics.draft = data.filter((p) => p.status === "Draft").length;
  satistics.inProgress = data.filter((p) => p.status === "InProgress").length;
  satistics.completed = data.filter((p) => p.status === "Completed").length;
  satistics.canceled = data.filter((p) => p.status === "Canceled").length;
  return satistics;
};

export const filterByStatus = (data, status) => {
  if (status === "all") {
    return data;
  }
  const resp = data.filter((p) => p.status === status);
  return resp;
};
