import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CardInfo from "./CardInfo";
import GridDataDigital from "./GridDataDigital";
import GridDataScan from "../Dashborad/GridDataScan";
import { useParams, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext/GlobalContext";

export default function Dashboard() {
  const { type } = useParams(); // Get the current tab type from URL
  const navigate = useNavigate();
  const [value, setValue] = useState(type || "scan"); // Set default to 'scan' if type is undefined
  const { setActiveService } = useContext(GlobalContext);

  useEffect(() => {
    if (type && type !== value) {
      setValue(type); // Sync state with URL parameter if it changes
    }
    setActiveService(type || "scan"); // Ensure the service is set, default to 'scan'
  }, [type, value, setActiveService]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveService(newValue);
    navigate(`/admin-panel/dashboard/${newValue}`); // Update URL on tab change
  };
  return (
    <div>
      <div>
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleChange}
              aria-label="Services Tab"
              sx={{
                "& .MuiTab-root": {
                  // This targets all Tab components inside TabList
                  // Your custom color for all tabs
                  color: "#1a477f",
                  fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  textTransform: "none",
                  "&.Mui-selected": {
                    // This targets the selected Tab
                    fontWeight: 600,
                    color: "#1a477f",
                  },
                },
              }}
            >
              <Tab label="Scanning" value="scan" />
              <Tab label="Digitalization" value="digital" />
            </TabList>
          </Box>
          <CardInfo />
          <div className="order-tab-container">
            <TabPanel sx={{ width: "100%",  overflow: "hidden"  }} value="scan">
              <GridDataScan />
            </TabPanel>

            <TabPanel  value="digital" sx={{ width: "100%", overflow: "hidden" }}>
              <GridDataDigital />
            </TabPanel>
          </div>
        </TabContext>
      </div>
    </div>
  );
}
