import React from "react";
import styles from "../../Styles/home.module.css";
import Logo from "../../assets/Images/LogoWhite.png";
import { useTranslation } from "react-i18next";
import ShineBorder from "../ui/shine-border";
import NumberTicker from "../ui/number-ticker";
import FlipText from "../ui/flip-text";
import TypingAnimation from "../ui/typing-animation";
import SparklesText from "../ui/sparkles-text";
import { Trans } from "react-i18next";

export default function Home2() {
  const { t } = useTranslation();
  const scan3DDetails = t("home2.scan3DDetails", { returnObjects: true });
  const viewer3DDetails = t("home2.viewer3DDetails", { returnObjects: true });
  const virtualTourDetails = t("home2.virtualTourDetails", {
    returnObjects: true,
  });
  const bimDetails = t("home2.bimDetails", { returnObjects: true });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.introduction}>
          <img className={styles.logo} src={Logo} alt="logo" />
          <div className={styles.introTextDetailsTxt}>
          <div style={{display:'flex'}}>
            <FlipText
              className={styles.introTitle1}
              word={t("home2.introTitle1")}
            />
            <p>&nbsp;&nbsp;&nbsp;</p>
            <FlipText
              className={styles.introTitle2}
              word={t("home2.introTitle2")}
            />
          </div>
          <div className={styles.introDetailsTxtCont}>
            <p className={styles.introDetails}>{t("home2.introDetails")}</p>
          </div>
          </div>
        </div>
        <div className={styles.scanToBim}>
          <div className={styles.scanToBimCard}>
            <div className={styles.stbTitleContainer}>
              <p className={styles.stbTitle}>{t("home2.stbTitle")}</p>
            </div>
            <div className={styles.stbSubTxtCont}>
              <p className={styles.stbSubTxt}>
                {t("home2.stbSubTxt1")}
                <span className={styles.txtHighlight}>
                  {t("home2.stbSubTxt2")}
                </span>
                {t("home2.stbSubTxt3")}
                <span className={styles.txtHighlight}>
                  {t("home2.stbSubTxt4")}
                </span>{" "}
                {t("home2.stbSubTxt5")}
              </p>
            </div>
            <div className={styles.ourInnovation}>
              <SparklesText
                className={styles.ourInnovationTxt}
                text={t("home2.ourInnovation")}
              />
              {/* <p className={styles.ourInnovationTxt}>OUR INNOVATION</p> */}
              <div className={styles.ourInnovationInfo}>
                <div className={styles.ourInnovation1}>
                  <div className={styles.ourInnovationItems}>
                    <p className={styles.txtHighlight2}>
                      <strong>{t("home2.innovation1")}</strong>{" "}
                      {t("home2.innovation1Sub")}
                    </p>
                  </div>
                  <div className={styles.ourInnovationItems}>
                    <p   className={styles.txtHighlight2}>     <Trans
                  
                      i18nKey="home2.innovation2"
                      values={{ product: t("home2.innovation2Sub") }}
                    >
                      ...
                    </Trans></p>
               
                  </div>
                </div>
                <div className={styles.ourInnovationItems}>
                  <p className={styles.txtHighlight2}>
                    {t("home2.innovation3")}
                    <strong> {t("home2.innovation3Sub")}</strong>{" "}
                    {t("home2.innovation3Sub2")}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.innovationImgCont}>
              <img
                className={styles.innovationImg}
                src="https://i.postimg.cc/9QMMzJ1q/image.png"
                alt="img-scan-to-bim"
              />
            </div>
          </div>
        </div>
        <div className={styles.whatWeDo}>
          <FlipText className={styles.title} word={t("home2.whatWeDo")} />
          <div className={styles.whatWeDoInfoCont}>
            <p className={styles.baseTxt}>
              {t("home2.whatWeDoDetails")}
              <strong>{t("home2.whatWeDoDetailsBold")} </strong>
              {t("home2.whatWeDoDetails2")}
            </p>
          </div>
          <div className={styles.achievements}>
            <div className={styles.achievementItem}>
              <img
                className={styles.achievementImg}
                src="https://i.postimg.cc/L6RvrVCc/Group-7.png"
                alt="completed-projects"
              />
              <p className={styles.baseTxt}>{t("home2.completedProjects")}</p>
              <p className={styles.achievementTitle}>
                <NumberTicker value={200} /> +
              </p>
            </div>
            <div className={styles.achievementItem}>
              <img
                className={styles.achievementImg}
                src="https://i.postimg.cc/KzBrWKnc/image.png"
                alt="nr-of-countries"
              />
              <p className={styles.baseTxt}>{t("home2.nrOfCountries")}</p>
              <p className={styles.achievementTitle}>
                <NumberTicker value={5} /> +
              </p>
            </div>
            <div className={styles.achievementItem}>
              <img
                className={styles.achievementImg}
                src="https://i.postimg.cc/QCDkZXbQ/image.png"
                alt="years-in-business"
              />
              <p className={styles.baseTxt}>{t("home2.yearsInBusiness")}</p>
              <p className={styles.achievementTitle}>
                <NumberTicker value={2} /> {t("home2.years")}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.ourProjects}>
          <p className={styles.title2}>{t("home2.ourProjects")}</p>
          <div className={styles.projectContent}>
            <ShineBorder
              className={styles.projectItem}
              color={["#A07CFE", "#FE8FB5", "#FFBE7B"]}
            >
              <img
                className={styles.projectImg}
                src="https://i.postimg.cc/QxnfNw5k/image.png"
                alt="interior-img"
              />
              <p className={styles.projectItemTxt}>{t("home2.interior")}</p>
            </ShineBorder>
            <ShineBorder
              className={styles.projectItem}
              color={["#A07CFE", "#FE8FB5", "#FFBE7B"]}
            >
              <img
                className={styles.projectImg}
                src="https://i.postimg.cc/3xvF8R3Y/image.png"
                alt="interior-img"
              />
              <p className={styles.projectItemTxt}>{t("home2.exterior")}</p>
            </ShineBorder>
            <ShineBorder
              className={styles.projectItem}
              color={["#A07CFE", "#FE8FB5", "#FFBE7B"]}
            >
              <img
                className={styles.projectImg}
                src="https://i.postimg.cc/sXXB2657/image.png"
                alt="interior-img"
              />
              <p className={styles.projectItemTxt}>{t("home2.site")}</p>
            </ShineBorder>
          </div>
          <div className={styles.whatWeOffer}>
            <div className={styles.whatWeOfferContent}>
              <FlipText
                className={styles.title2}
                word={t("home2.whatWeOffer")}
              />
              {/* <p className={styles.title2}>{t("home2.whatWeOffer")}</p> */}
              <p className={styles.baseTxtBlack}>{t("home2.offerDetails")}</p>
            </div>
          </div>
        </div>

        <div className={styles.propertyScanning}>
          <TypingAnimation
            className={styles.title}
            text={t("home2.propertyScanning")}
          />
          <div>
            <TypingAnimation
              className={styles.stbSubTxt3}
              text={t("home2.serviceAlbaniaKosovo")}
            />
            <TypingAnimation
              className={styles.stbSubTxt3}
              text={t("home2.propertyInfoScanRequired")}
            />
          </div>
        </div>
        {/* Property Scan */}
        <div className={styles.virtunexModels}>
          <div className={styles.modelContent}>
            <div className={styles.modelInfo}>
              <div className={styles.topInfoModel}>
                <img
                  className={styles.iconInfo}
                  src="https://i.postimg.cc/SsmsFHyx/image.png"
                  alt="pay-icon"
                />
                <p className={styles.modelSubTxt}>
                  {t("home2.oneTimePayment")}
                </p>
              </div>
              <div>
                <p className={styles.modelTitle}>{t("home2.scan3D")}</p>
              </div>
              <ul className={styles.listTxt}>
                {scan3DDetails.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div>
              <img
                className={styles.modelImg}
                src="https://i.postimg.cc/wx0tHxR8/image.png"
                alt="scan-model"
              />
            </div>
          </div>
          <div className={styles.plusImgCont}>
            <img
              className={styles.plusImg}
              src="https://i.postimg.cc/y8SWFFQs/image.png"
              alt="plus"
            />
          </div>
          <div className={`${styles.modelContent} ${styles.modelContent2}`}>
            <div>
              <img
                className={styles.modelImg}
                src="https://i.postimg.cc/m2ntzdSm/image.png"
                alt="virtual-tour"
              />
            </div>
            <div className={styles.modelInfo}>
              <div className={styles.topInfoModel}>
                <img
                  className={styles.iconInfo}
                  src="https://i.postimg.cc/XqJYXFBM/image.png"
                  alt="pay-icon"
                />
                <p className={styles.modelSubTxt}>
                  {t("home2.monthlyPayment")}
                </p>
              </div>
              <div>
                <p className={styles.modelTitle}>{t("home2.virtualTours")}</p>
              </div>
              <ul className={styles.listTxt}>
                <ul className={styles.listTxt}>
                  {virtualTourDetails.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.modelShowCases}>
          <p className={styles.introTitle1}>{t("home2.withoutVirtunex")}</p>
          <img
            className={styles.propertyImg}
            src={t("home2.withoutVirtunexScanImgUrl")}
            alt="model-property"
          />
          <p className={styles.baseTxt}>{t("home2.withoutVirtunexDetails")}</p>
        </div>
        <div className={styles.modelShowCases}>
          <p className={styles.introTitle1}>{t("home2.withVirtunex")}</p>
          <img
            className={styles.propertyImg}
            src={t("home2.withVirtunexScanImgUrl")}
            alt="model-property"
          />
          <p className={styles.baseTxt}>{t("home2.withVirtunexDetails")}</p>
        </div>
        <div style={{ marginTop: "30px" }}></div>
        <div className={styles.propertyScanning}>
          <TypingAnimation
            className={styles.title}
            text={t("home2.propertyDigitalization")}
          />
          <div>
            <TypingAnimation
              className={styles.stbSubTxt3}
              text={t("home2.serviceWorldwide")}
            />
            <TypingAnimation
              className={styles.stbSubTxt3}
              text={t("home2.propertyInfoDigitalRequired")}
            />
          </div>

          {/* <p className={styles.title}>Property Digitalization</p>
          <p className={styles.stbSubTxt3}>
            WE OFFER THIS SERVICE <strong>WORLDWIDE</strong>
          </p>
          <p className={styles.stbSubTxt3}>PROPERTY POINTCLOUD DATA REQUIRED</p> */}
        </div>
        {/* Property Digitalization */}
        <div className={styles.virtunexModels}>
          <div className={styles.modelContent}>
            <div className={styles.modelInfo}>
              <div className={styles.topInfoModel}>
                <img
                  className={styles.iconInfo}
                  src="https://i.postimg.cc/SsmsFHyx/image.png"
                  alt="pay-icon"
                />
                <p className={styles.modelSubTxt}>
                  {t("home2.oneTimePayment")}
                </p>
              </div>
              <div>
                <p className={styles.modelTitle}> {t("home2.bim")}</p>
              </div>
              <ul className={styles.listTxt}>
                {bimDetails.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div className={styles.modelImgCont}>
              <img
                className={styles.modelImg}
                src="https://i.postimg.cc/sDVjC5Th/image.png"
                alt="scan-model"
              />
              <div className={styles.modelTypes}>
                <p className={styles.modelSubTxt}>{t("home2.installations")}</p>
                <p className={styles.modelSubTxt}>{t("home2.furniture")}</p>
                <p className={styles.modelSubTxt}>{t("home2.architecture")}</p>
              </div>
            </div>
          </div>
          <div className={styles.plusImgCont}>
            <img
              className={styles.plusImg}
              src="https://i.postimg.cc/y8SWFFQs/image.png"
              alt="plus"
            />
          </div>
          <div className={`${styles.modelContent} ${styles.modelContent2}`}>
            <div>
              <img
                className={styles.modelImg}
                src="https://i.postimg.cc/SshxWRjs/image.png"
                alt="virtual-tour"
              />
            </div>
            <div className={styles.modelInfo}>
              <div className={styles.topInfoModel}>
                <img
                  className={styles.iconInfo}
                  src="https://i.postimg.cc/XqJYXFBM/image.png"
                  alt="pay-icon"
                />
                <p className={styles.modelSubTxt}>
                  {t("home2.monthlyPayment")}
                </p>
              </div>
              <div>
                <p className={styles.modelTitle}>{t("home2.viewer3D")}</p>
              </div>
              <ul className={styles.listTxt}>
                {viewer3DDetails.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.modelShowCases}>
          <p className={styles.introTitle1}>{t("home2.withoutVirtunex")}</p>
          <img
            className={styles.propertyImg}
            src={t("home2.withoutVirtunexDigitalImgUrl")}
            alt="model-property"
          />
          <p className={styles.baseTxt}>{t("home2.withoutVirtunexDetails2")}</p>
        </div>
        <div className={styles.modelShowCases}>
          <p className={styles.introTitle1}>{t("home2.withVirtunex")}</p>
          <img
            className={styles.propertyImg}
            src={t("home2.withVirtunexDigitalImgUrl")}
            alt="model-property"
          />
          <p className={styles.baseTxt}>{t("home2.withVirtunexDetails2")}</p>
        </div>
      </div>
    </div>
  );
}
