import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { getForgeToken } from "../../API/apiService";
import {
  getAllWalls,
  getAllWindows,
  getAllFloors,
  getAllCeilings,
  getAllColumns,
  getAllConduitFittings,
  getAllConduits,
  getAllCasework,
  getAllDoors,
  getAllDuctFittings,
  getAllDucts,
  getAllFurniture,
  getAllGenericModels,
  getAllLightingFixtures,
  getAllMechanicalEquipment,
  getAllPipeFittings,
  getAllPipes,
  getAllPlumbingFixtures,
  getAllRailings,
  getAllSpecialityEquipment,
  getAllStairs,
  getAllStructuralColumn,
  getAllTopography,
  getAllSites,
  getAllStructuralFraming,
  isolateElements,
  getAllArchitectureCategory,
  getAllFurnitureCategory,
  getAllMEPFCategory,
  getAllSiteCategories,
  getAllPlantings,
  removeElementsFromIsolation,
  getAllAvailableFloors,
  isolateElementsByFloor,
  getAllCurtainPanels,
  getAllMullionsArch,
  getAllRampsArch,
  getAllRoofsArch,
  getAllAirTerminalsInst,
  getAllCableTraysInst,
  getAllElectricalEquipmentInst,
  getAllElectricalFixturesInst,
  getAllSprinklersInst,
  getAllLightingDevicesInst,
  getAllParkingsSite,
  getAllEntourageSite
} from "../../Helpers/forgeViewerUtils";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";


const ForgeViewer = forwardRef(({ urn }, ref) => {
  const [viewer, setViewer] = useState(null);
  const [floors, setFloors] = useState([]); // List of floors for the dropdown
const [selectedFloor, setSelectedFloor] = useState(null); // Selected floor
  const viewerRef = useRef(null);

  useEffect(() => {
    if (!urn) return;

    const initializeViewer = async () => {
      if (!viewerRef.current) return;
      const options = {
        env: "AutodeskProduction",
        getAccessToken: getForgeToken,
      };

      Autodesk.Viewing.Initializer(options, () => {
        const newViewer = new Autodesk.Viewing.GuiViewer3D(viewerRef.current);
        newViewer.start();
        setViewer(newViewer);

        const documentId = "urn:" + urn;
        Autodesk.Viewing.Document.load(
          documentId,
          (doc) => {
            onDocumentLoadSuccess(doc, newViewer);
          },
          onDocumentLoadFailure
        );
      });

    };

    initializeViewer();


    return () => {
      if (viewer) {
        viewer.finish();
      }
    };
  }, [urn]);

  const onDocumentLoadSuccess = async (doc, viewerInstance) => {
    const viewables = doc.getRoot().getDefaultGeometry();
    viewerInstance.loadDocumentNode(doc, viewables).then(async () => {
      viewerInstance.addEventListener(
        Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        async () => {
          viewerInstance.setEnvMapBackground(false);
          viewerInstance.setBackgroundColor(255, 255, 255, 255, 255, 255);

          // Fetch floors after geometry is loaded
          const fetchedFloors = await getAllAvailableFloors(viewerInstance);
          
          setFloors(fetchedFloors);
        },
        { once: true }
      );
    });
  };


  const onDocumentLoadFailure = (viewerErrorCode) => {
    //console.error("onDocumentLoadFailure() - errorCode:" + viewerErrorCode);
  };

  const handleFloorChange = async (event) => {
    const floorId = event.target.value;
    setSelectedFloor(floorId);

    // Isolate the selected floor in the viewer
    if (viewer) {
      await isolateElementsByFloor(floorId, viewer);
    }
  };
  useImperativeHandle(ref, () => ({
    getWalls: () => getAllWalls(viewer),
    getWindows: () => getAllWindows(viewer),
    getFloors: () => getAllFloors(viewer),
    getCasework: () => getAllCasework(viewer),
    getCeilings: () => getAllCeilings(viewer),
    getColumns: () => getAllColumns(viewer),
    //New
    getCurtainPanels: () => getAllCurtainPanels(viewer),
    getMullionsArch: () => getAllMullionsArch(viewer),
    getRampsArch: () => getAllRampsArch(viewer),
    getRoofsArch: () => getAllRoofsArch(viewer),
    getAirTerminalsInst: () => getAllAirTerminalsInst(viewer),
    getCableTraysInst: () => getAllCableTraysInst(viewer),
    getElectricalEquipmentInst: () => getAllElectricalEquipmentInst(viewer),
    getElectricalFixturesInst: () => getAllElectricalFixturesInst(viewer),
    getSprinklersInst: () => getAllSprinklersInst(viewer),
    getLightingDevicesInst: () => getAllLightingDevicesInst(viewer),
    getParkingsSite: () => getAllParkingsSite(viewer),
    getEntourageSite: () => getAllEntourageSite(viewer),

    getConduitFittings: () => getAllConduitFittings(viewer),
    getConduits: () => getAllConduits(viewer),
    getDoors: () => getAllDoors(viewer),
    getDuctFittings: () => getAllDuctFittings(viewer),
    getDucts: () => getAllDucts(viewer),
    getElementsByCategory: () => getAllElementsByCategory(viewer),
    getFurniture: () => getAllFurniture(viewer),
    getGenericModels: () => getAllGenericModels(viewer),
    getLightingFixtures: () => getAllLightingFixtures(viewer),
    getMechanicalEquipment: () => getAllMechanicalEquipment(viewer),
    getPipeFittings: () => getAllPipeFittings(viewer),
    getPipes: () => getAllPipes(viewer),
    getPlumbingFixtures: () => getAllPlumbingFixtures(viewer),
    getRailings: () => getAllRailings(viewer),
    getSpecialityEquipment: () => getAllSpecialityEquipment(viewer),
    getStairs: () => getAllStairs(viewer),
    getStructuralColumns: () => getAllStructuralColumn(viewer),
    getPlantings: () => getAllPlantings(viewer),
    getTopography: () => getAllTopography(viewer),
    getSite: () => getAllSites(viewer),
    getStructuralFraming: () => getAllStructuralFraming(viewer),
    getArchitectureCategory: (action) => getAllArchitectureCategory(viewer, action),
    getFurnitureCategory: (action) => getAllFurnitureCategory(viewer, action),
    getMEPFCategory: (action) => getAllMEPFCategory(viewer, action),
    getSiteCategory: (action) => getAllSiteCategories(viewer, action),
    isolateGroup: (dbIds) => isolateElements(viewer, dbIds),
    removeFromIsolation: () => removeElementsFromIsolation(viewer, dbIds),
   
  }));

  // Define other element retrieval methods similarly using getAllElementsByCategory

  return (
    <div className="mainCont3d">
        <FormControl
        variant="outlined"
        sx={{ margin: "15px",marginTop:'0px', minWidth: "100px" ,maxWidth:"150px", marginLeft:"50px"}}
        size="small"
      >
        <InputLabel
          sx={{
            fontSize: "11px",
            padding: "8px",
            fontWeight: "600",
            textAlign: "left",
          }}
          id="floor-select-label"
        >
          Select Floor
        </InputLabel>
        <Select
          sx={{
            minWidth: "160px",
            padding: "2px",
            borderRadius: "8px",
          }}
          labelId="floor-select-label"
          value={selectedFloor || ""}
          onChange={e => handleFloorChange(e)}
          label="Select Floor"
          MenuProps={{
            PaperProps: {
              sx: {
                width: "220px",
              },
            },
          }}
        >
          {floors.map((floor) => (
            <MenuItem key={floor} value={floor}>
              {floor}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div
        className="auto-desk-container"
        ref={viewerRef}
        // style={{display:'flex',justifyItems:'center', width: "50%", height: "53vh", padding:'10px' , zIndex:1}}
      ></div>
    </div>
  );
});


export default ForgeViewer;
