import React from "react";
import { Typography, Box } from "@mui/material";
import Logo from '../../assets/Images/Logo.png';
import CongratsImg from '../../assets/Images/Congrats.png';
import { resendEmailConfirmation } from "../../API/apiService";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function ConfirmEmail() {
  const { t } = useTranslation();

  const resendEmail = async (email) => {
    try {
      const response = await resendEmailConfirmation(email);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleResendEmailConfirmation = async () => {
    const email = localStorage.getItem('emailConfirm');
    if (!email) {
      toast.error(t('confirmEmail.resendEmailFailure'));
      return;
    }
    const response = resendEmail(email);
    toast.promise(
      response,
      {
        loading: t('confirmEmail.resendEmailLoading'),
        success: t('confirmEmail.resendEmailSuccess'),
        error: t('confirmEmail.resendEmailError'),
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };

  return (
    <div className="congrats-register-container">
      <div className="congrats-reg-content">
        <div>
          <img src={Logo} alt="logo-img" />
        </div>
        <div className="congrats-reg-form">
          <img src={CongratsImg} alt="congrats-img" />
          <p className="reset-info">
            {t('confirmEmail.confirmationMessage')}
          </p>
          <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
            <Typography sx={{ color: 'grey' }}>
              {t('confirmEmail.didNotGetEmail')}
            </Typography>
            <Typography
              sx={{ color: '#1a477f', cursor: 'pointer' }}
              onClick={handleResendEmailConfirmation}
            >
              {t('confirmEmail.resendButton')}
            </Typography>
          </Box>
        </div>
      </div>
    </div>
  );
}
