import * as React from "react";
import PropTypes from "prop-types";
import { alpha, duration } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";
import { visuallyHidden } from "@mui/utils";
import NoDataImg from "../../../assets/Images/noData.png";
import { useState, useEffect } from "react";
import {
  addScanlWorkLink,
  putScanViewerlink,
  updateStatusScan,
  addScanPhotoUrl,
  cancelProjectAdmin,
  deleteRealEstateScan,
  notifyProjectCompleted,
  markProjectAsPaid,
} from "../../../API/apiService";
import { GlobalContext } from "../../../GlobalContext/GlobalContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px", // Add this line for border radius
  boxShadow: 24,
  p: 4,
};

const rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "projectName",
    numeric: false,
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "workLink",
    numeric: false,
    disablePadding: false,
    label: "Work Link",
  },
  {
    id: "scanLink",
    numeric: false,
    disablePadding: false,
    label: "Scan Link",
  },
  {
    id: "photoLink",
    numeric: false,
    disablePadding: false,
    label: "Photo Link",
  },
  {
    id: "businessName",
    numeric: false,
    disablePadding: false,
    label: "Business Name",
  },
  {
    id: "emailBusiness",
    numeric: false,
    disablePadding: false,
    label: "Business Email",
  },
  {
    id: "scanFile",
    numeric: false,
    disablePadding: false,
    label: "Scan File",
  },
  {
    id: "googleMap",
    numeric: false,
    disablePadding: false,
    label: "Google Map",
  },
  {
    id: "usedCoupon",
    numeric: false,
    disablePadding: false,
    label: "Used Coupon",
  },
  // { id: "workLink", numeric: false, disablePadding: false, label: "Work Link" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  {
    id: "propertyType",
    numeric: false,
    disablePadding: false,
    label: "Property Type",
  },
  {
    id: "scanningType",
    numeric: false,
    disablePadding: false,
    label: "Scanning Scope",
  },
  {
    id: "submissionTine",
    numeric: false,
    disablePadding: false,
    label: "Submission Time",
  },

  {
    id: "ownerName",
    numeric: false,
    disablePadding: false,
    label: "Owner Name",
  },
  {
    id: "sizeOfSpace",
    numeric: true,
    disablePadding: false,
    label: "Size of space",
  },
  {
    id: "sizeOfSite",
    numeric: true,
    disablePadding: false,
    label: "Size of site",
  },
  {
    id: "cadastralRegion",
    numeric: false,
    disablePadding: false,
    label: "Cadastral Region",
  },
  {
    id: "propertyNumber",
    numeric: false,
    disablePadding: false,
    label: "Property Number",
  },
  {
    id: "volume",
    numeric: false,
    disablePadding: false,
    label: "Volume",
  },
  {
    id: "page",
    numeric: false,
    disablePadding: false,
    label: "Page",
  },
  {
    id: "nomenclatureMap",
    numeric: false,
    disablePadding: false,
    label: "Nomeclature map",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Location link",
  },
  {
    id: "referencePrice",
    numeric: false,
    disablePadding: false,
    label: "Reference Price",
  },
  { id: "country", numeric: false, disablePadding: false, label: "Country" },
  { id: "region", numeric: false, disablePadding: false, label: "Region" },
  {
    id: "fullAddress",
    numeric: false,
    disablePadding: false,
    label: "Full Address",
  },
  {
    id: "siteName",
    numeric: false,
    disablePadding: false,
    label: "Site Name",
  },
  {
    id: "siteEmail",
    numeric: false,
    disablePadding: false,
    label: "Site Email",
  },
  {
    id: "sitePhoneNumber",
    numeric: false,
    disablePadding: false,
    label: "Site Phone Number",
  },
  {
    id: "subscription",
    numeric: false,
    disablePadding: false,
    label: "Connected with Subs",
  },
  {
    id: "totalPrice",
    numeric: true,
    disablePadding: false,
    label: "Total Price",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all projects" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const [openDeleteProjectModal, setOpenDeleteProjectModal] = useState(false);
  const { numSelected, setSelected } = props;
  const handleCloseDeleteProjectModal = () => setOpenDeleteProjectModal(false);
  const { projectModified, setProjectModified } =
    React.useContext(GlobalContext);

  const handleOpenDeleteProjectModal = () => {
    setOpenDeleteProjectModal(true);
  };

  const deleteProject = async () => {
    try {
      const response = await deleteRealEstateScan(numSelected[0]);
      setOpenDeleteProjectModal(false);
      setProjectModified(!projectModified);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOpenDeleteProjectModal(false);
      setSelected([]);
    }
  };

  const handleDeleteProject = async () => {
    if (numSelected[0] === "")
      return toast.error("Something went wrong.Please refresh the page");
    const response = deleteProject();
    toast.promise(
      response,
      {
        loading: "Deleting Project...",
        success: <b>Project deleted!</b>,
        error: <b>Deleting Project failed. Please try again.</b>,
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected.length > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected.length > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected.length} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Projects
          </Typography>
        )}

        {numSelected.length > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon onClick={() => handleOpenDeleteProjectModal()} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      <Modal
        open={openDeleteProjectModal}
        onClose={handleCloseDeleteProjectModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Are you sure you want to delete this project?
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDeleteProject}
              sx={{ mr: 1 }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseDeleteProjectModal}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("projectName");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { scanProjects, projectModified, setProjectModified, loading } =
    React.useContext(GlobalContext);
  const [openScanModal, setOpenScanModal] = useState(false);
  const [openWorkModal, setOpenWorkModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openPhotoUrlModal, setOpenPhotoUrlModal] = useState(false);
  const [openDeleteProjectModal, setOpenDeleteProjectModal] = useState(false);
  const [workLink, setWorkLink] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [scanViewerLink, setPutScanViewerLink] = useState("");
  const [canceledReason, setCanceledReason] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedProject, setSelectedProject] = useState({});
  const [projectStatus, setProjectStatus] = useState("");
  const [selectedRow, setSelectedRow] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.projectId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    if (selected.includes(id)) {
      setSelected([]); // Deselect if the row is already selected
    } else {
      setSelected([id]); // Select only the new row
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      scanProjects.length > 0
        ? stableSort(scanProjects, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : [], // Return an empty array if scanProjects.data is empty
    [scanProjects, order, orderBy, page, rowsPerPage]
  );

  const statusColors = {
    Draft: "#e7e7e7", // Light grey
    InProgress: "#dbf0fe", // Pale Blue
    Canceled: "#ffe3d4", // Light red
    Completed: "#e4f4e5", // Pale Green
    Refreshed: "#a1feff",
  };

  const textStatusColor = {
    Draft: "#3D3D3D", // Light grey
    InProgress: "#1F4C89", // Pale Green
    Canceled: "#7E1310", // Light red
    Completed: "#254227",
    Refreshed: "#125767",
  };

  const handleOpenScanModal = (rowId) => {
    setSelectedProjectId(rowId);
    setOpenScanModal(true);
  };

  const handleCloseScanModal = () => setOpenScanModal(false);
  const handleCloseCancelModal = () => setOpenCancelModal(false);
  const handleCloseStatusModal = () => setOpenStatusModal(false);
  const handleClosePhotoUrlModal = () => setOpenPhotoUrlModal(false);
  const handleCloseDeleteProjectModal = () => setOpenDeleteProjectModal(false);

  const handleOpenWorkModal = (rowId, row) => {
    setSelectedProjectId(rowId);
    setSelectedProject(row);
    setOpenWorkModal(true);
  };

  const handleOpenStatusModal = (rowId, status) => {
    setSelectedProjectId(rowId);
    setProjectStatus(status);
    setOpenStatusModal(true);
  };

  const handleOpenCancelModal = (row) => {
    setSelectedProjectId(row.projectId);
    setSelectedRow(row);
    setOpenCancelModal(true);
  };

  const handleOpenPhotoUrlModal = (rowId) => {
    setSelectedProjectId(rowId);
    setOpenPhotoUrlModal(true);
  };

  const handleOpenDeleteProjectModal = (rowId) => {
    toast.success(rowId);
    setSelectedProjectId(rowId);
    setOpenDeleteProjectModal(true);
  };

  const addWorkLink = async () => {
    const payload = {
      workLink: workLink,
    };
    try {
      const response = await addScanlWorkLink(selectedProjectId, payload);
      setProjectModified(!projectModified);
      if (response?.success) {
        const notifyPayload = {
          projectId: selectedProject.projectId,
          projectName: selectedProject.projectName,
          userId: selectedProject.userId,
          serviceType: "scan",
          isUpdated: !!selectedProject.workLink,
        };
        await notifyProjectCompleted(notifyPayload);
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOpenWorkModal(false);
      setWorkLink("");
    }
  };
  const handleAddWorkLink = async () => {
    if (selectedProjectId === "") return toast.error("Something went wrong.");
    if (workLink === "") return toast.error("Please enter the work link");
    const response = addWorkLink();

    toast.promise(
      response, // Ensure this returns a promise
      {
        loading: "Adding work link...",
        success: <b>Work link added successfully!</b>,
        error: <b>Adding work link failed. Please try again.</b>,
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };

  const changeStatus = async () => {
    // const payload = {
    //   newStatus: projectStatus === "InProgress" ? "Draft" : "InProgress",
    // };
    // try {
    //   const response = await updateStatusScan(selectedProjectId, payload);
    //   setOpenStatusModal(false);
    //   setProjectModified(!projectModified);
    //   return response;
    // } catch (error) {
    //   throw error;
    // } finally {
    //   setOpenWorkModal(false);
    // }

    try {
      const response = await markProjectAsPaid(selectedProjectId, "scan");
      setOpenStatusModal(false);
      setProjectModified(!projectModified);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOpenWorkModal(false);
    }
  };

  const handleChangeStatus = async () => {
    if (selectedProjectId === "") return toast.error("Something went wrong.");
    if (!(projectStatus === "InProgress" || projectStatus === "Draft"))
      return toast.error("Current project status is not valid for update");
    const response = changeStatus();
    toast.promise(
      response, // Ensure this returns a promise
      {
        loading: "Changing status...",
        success: <b>Status changed successfully!</b>,
        error: <b>Changing status failed. Please try again.</b>,
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };

  const putScanLink = async () => {
    const payload = {
      workLink: scanViewerLink,
    };
    try {
      const response = await putScanViewerlink(selectedProjectId, payload);
      setProjectModified(!projectModified);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOpenScanModal(false);
      setPutScanViewerLink("");
    }
  };

  const handlePutScanLink = async () => {
    if (selectedProjectId === "")
      return toast.error("Something went wrong.Please refresh the page");
    if (scanViewerLink === "") return toast.error("Please enter the scan link");
    const response = putScanLink();
    toast.promise(
      response, // Ensure this returns a promise
      {
        loading: "Adding scan link...",
        success: <b>Scan link added successfully!</b>,
        error: <b>Adding scan link failed. Please try again.</b>,
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };

  const cancelProject = async () => {
    const payload = {
      newStatus: "Canceled",
      userId: selectedRow.userId,
      canceledReason: canceledReason,
      serviceType: "Scan",
      projectName: selectedRow.projectName,
    };

    try {
      const response = await cancelProjectAdmin(selectedProjectId, payload);
      setOpenCancelModal(false);
      setProjectModified(!projectModified);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOpenCancelModal(false);
      setCanceledReason("");
    }
  };

  const handleCancelProject = async () => {
    if (selectedProjectId === "") return toast.error("Something went wrong.");
    if (canceledReason === "")
      return toast.error(
        "Please enter the the reason why you want to cancel the project."
      );
    const response = cancelProject();

    toast.promise(
      response,
      {
        loading: "Canceling the order...",
        success: "Order canceled. User was notify via mail!",
        error: "Canceling the order failed. Please try again.",
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };

  const addPhotoUrl = async () => {
    const payload = {
      photoUrl: photoUrl,
    };

    try {
      const response = await addScanPhotoUrl(selectedProjectId, payload);
      setProjectModified(!projectModified);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOpenPhotoUrlModal(false);
      setPhotoUrl("");
    }
  };

  const handleAddPhotoUrl = async () => {
    if (selectedProjectId === "")
      return toast.error("Something went wrong.Please refresh the page");
    if (photoUrl === "") return toast.error("Please enter the photo link");
    const response = addPhotoUrl();
    toast.promise(
      response,
      {
        loading: "Adding photo link...",
        success: <b>Photo link added successfully!</b>,
        error: <b>Adding photo link failed. Please try again.</b>,
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };

  const handleCloseWorkModal = () => setOpenWorkModal(false);

  return (
    <Box sx={{ width: "100%" }}>
      {visibleRows?.length === 0 && !loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 4,
          }}
        >
          <img
            style={{
              width: "420px",
            }}
            src={NoDataImg}
            alt="no-data-img"
          />
          <Typography
            sx={{
              color: "grey",
              fontSize: 16,
              mt: -5,
            }}
          >
            No data found
          </Typography>
        </Box>
      ) : (
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected}
            setSelected={setSelected}
          />
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.projectId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <>
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.projectId)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.projectId}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            sx={{ marginTop: 1.5 }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.projectName}
                        </TableCell>
                        <TableCell align="left">
                          <a
                            href={row.workLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                            }}
                          >
                            {row.workLink ? "Work Link" : ""}
                          </a>
                        </TableCell>
                        <TableCell align="left">
                          <a
                            href={row.scanLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                            }}
                          >
                            {row.scanLink ? "Scan Link" : ""}
                          </a>
                        </TableCell>
                        <TableCell align="left">
                          <a
                            href={row.photoUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                            }}
                          >
                            {row.photoUrl ? "Photo Link" : ""}
                          </a>
                        </TableCell>
                        <TableCell align="left">
                          {row.businessName || "-"}
                        </TableCell>
                        <TableCell align="left">
                          {row.emailBusiness || "-"}
                        </TableCell>
                        <TableCell align="left">
                          {row.scanFile ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="left">
                          {row.googleMap ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="left">
                          {row.couponName || "-"}
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            style={{
                              textWrap: "nowrap",
                              fontSize: "12px",
                              padding: "5px",
                              fontWeight: 400,
                              borderRadius: "6px",
                              textAlign: "center",
                              backgroundColor: statusColors[row.status],
                              color: textStatusColor[row.status],
                            }}
                          >
                            {" "}
                            {row.status === "InProgress"
                              ? "In Progress"
                              : row.status}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.propertyType}
                        </TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.scanningType.split([0])}
                        </TableCell>
                        <TableCell align="left">{row.submissionTime}</TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.ownerName}
                        </TableCell>
                        <TableCell align="center">
                          {row.propertyInfoScan.sizeOfSpace}
                        </TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.sizeOfSite}
                        </TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.cadastralRegion === ""
                            ? "NA"
                            : row.propertyInfoScan.cadastralRegion}
                        </TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.propertyNumber === ""
                            ? "NA"
                            : row.propertyInfoScan.propertyNumber}
                        </TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.volume === ""
                            ? "NA"
                            : row.propertyInfoScan.volume}
                        </TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.page === ""
                            ? "NA"
                            : row.propertyInfoScan.page}
                        </TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.nomenclatureMap === ""
                            ? "NA"
                            : row.propertyInfoScan.nomenclatureMap}
                        </TableCell>
                        <TableCell align="left">
                          <a
                            href={
                              row.propertyInfoScan?.address?.googleMapLocation
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                            }}
                          >
                            {row.propertyInfoScan?.address?.googleMapLocation
                              ? "Location Link"
                              : ""}
                          </a>
                        </TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.referencePrice === ""
                            ? "NA"
                            : row.propertyInfoScan.referencePrice}
                        </TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.address.country}
                        </TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.address.region}
                        </TableCell>
                        <TableCell align="left">
                          {row.propertyInfoScan.address.addressLine}
                        </TableCell>
                        <TableCell align="left">
                          {row.siteContact.name}
                        </TableCell>
                        <TableCell align="left">
                          {row.siteContact.email}
                        </TableCell>
                        <TableCell align="left">
                          {row.siteContact.phoneNumber}
                        </TableCell>
                        <TableCell align="center">
                          {row?.subscriptionId !== null ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="center">
                          {row.totalPrice?.toFixed(2)} €
                        </TableCell>
                        {row.status !== "Canceled" && (
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                height: "fit-content",
                                gap: 2,
                              }}
                            >
                              {row.status === "Draft" && (
                                <Button
                                  sx={{
                                    fontSize: 12,
                                    textTransform: "none",
                                    width: 120,
                                    display: "flex",
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    handleOpenStatusModal(
                                      row.projectId,
                                      row.status
                                    );
                                  }}
                                >
                                  Mark as in progress
                                </Button>
                              )}
                              <Button
                                sx={{
                                  fontSize: 13,
                                  textTransform: "none",
                                  width: 120,
                                  color: "#002984",
                                }}
                                variant="outlined"
                                color="inherit"
                                onClick={() => {
                                  handleOpenPhotoUrlModal(row.projectId);
                                }}
                              >
                                {row.photoUrl
                                  ? "Update photo link"
                                  : "Add photo link"}
                              </Button>
                              <Button
                                sx={{
                                  fontSize: 13,
                                  textTransform: "none",
                                  width: 120,
                                }}
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                  handleOpenScanModal(row.projectId);
                                }}
                              >
                                {row.scanLink
                                  ? "Update scan link "
                                  : "Add Scan link"}
                              </Button>
                              <Button
                                sx={{
                                  fontSize: 13,
                                  textTransform: "none",
                                  width: 120,
                                }}
                                variant="outlined"
                                color="success"
                                onClick={() => {
                                  handleOpenWorkModal(row.projectId, row);
                                }}
                              >
                                {row.workLink
                                  ? "Update work link "
                                  : "Add work link"}
                              </Button>
                              {/* {row.status === "Draft" && (
                            <Button
                              sx={{
                                fontSize: 13,
                                textTransform: "none",
                                width: 120,
                              }}
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                handleOpenCancelModal(row);
                              }}
                            >
                              Cancel
                            </Button>
                          )} */}
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 0,
            }}
          >
            <Box>
              <Switch
                sx={{ mt: 0 }}
                checked={dense}
                onChange={handleChangeDense}
              />
              Dense padding
            </Box>

            <TablePagination
              sx={{
                display: "flex",
                justifyContent: "center", // Centers horizontally
                alignItems: "center", // Centers vertically
                "& .MuiTablePagination-actions": {
                  display: "flex",
                  alignItems: "center", // Ensures the arrows are centered
                  mt: 1.5,
                },
              }}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={scanProjects.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Paper>
      )}
      <Modal
        open={openPhotoUrlModal}
        onClose={handleClosePhotoUrlModal}
        aria-labelledby="modal-work-link"
        aria-describedby="modal-work-link-description"
      >
        <Box sx={style}>
          <h2 style={{ marginBottom: 12 }} id="modal-work-link">
            Add Photo Link
          </h2>
          <TextField
            InputLabelProps={{
              sx: {
                marginLeft: 0,
              },
            }}
            onChange={(e) => {
              setPhotoUrl(e.target.value);
            }}
            label="Photo link"
            variant="outlined"
            fullWidth
          />
          <Button
            onClick={handleAddPhotoUrl}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openScanModal}
        onClose={handleCloseScanModal}
        aria-labelledby="modal-scan-link"
        aria-describedby="modal-scan-link-description"
      >
        <Box sx={style}>
          <h2 style={{ marginBottom: 12 }} id="modal-scan-link">
            Add Scan Link
          </h2>
          <TextField
            InputLabelProps={{
              sx: {
                marginLeft: 0,
              },
            }}
            onChange={(e) => setPutScanViewerLink(e.target.value)}
            label="Scan Link"
            variant="outlined"
            fullWidth
          />
          <Button
            onClick={handlePutScanLink}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openWorkModal}
        onClose={handleCloseWorkModal}
        aria-labelledby="modal-work-link"
        aria-describedby="modal-work-link-description"
      >
        <Box sx={style}>
          <h2 style={{ marginBottom: 12 }} id="modal-work-link">
            Add Work Link
          </h2>
          <TextField
            InputLabelProps={{
              sx: {
                marginLeft: 0,
              },
            }}
            onChange={(e) => {
              setWorkLink(e.target.value);
            }}
            label="Work Link"
            variant="outlined"
            fullWidth
          />
          <Button
            onClick={handleAddWorkLink}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openCancelModal}
        onClose={handleCloseCancelModal}
        aria-labelledby="modal-work-link"
        aria-describedby="modal-work-link-description"
      >
        <Box sx={style}>
          <h2
            style={{ marginBottom: 12, fontSize: "16px" }}
            id="modal-work-link"
          >
            Enter the reason why you want to cancel this project:
          </h2>
          <TextField
            InputLabelProps={{
              sx: {
                marginLeft: 0,
              },
            }}
            onChange={(e) => {
              setCanceledReason(e.target.value);
            }}
            label="Reason"
            variant="outlined"
            fullWidth
          />{" "}
          <Button
            onClick={handleCancelProject}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openStatusModal}
        onClose={handleCloseStatusModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Are you sure you want to change the project status to "In Progress"?
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleChangeStatus}
              sx={{ mr: 1 }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseStatusModal}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openDeleteProjectModal}
        onClose={handleCloseDeleteProjectModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Are you sure you want to delete this project"?
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleChangeStatus}
              sx={{ mr: 1 }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseDeleteProjectModal}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
