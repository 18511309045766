import React, { useState } from "react";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CalendlyWidget from "./CalendlyWidget";
import {
  scheduleDemo,
  getCurrentUser,
} from "../../API/apiService";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; 
import "dayjs/locale/en";
import "dayjs/locale/sq";

export default function MoreInfo() {
  const { t } = useTranslation(); 
  const [email, setEmail] = useState("");
  const [formattedDateTime, setFormattedDateTime] = useState("");

  return (
    <div className="more-info-cont">
      <div>
        <p className="gradient-text more-info-title">
          <span>{t("present.wantMoreInfo")}</span>
        </p>
        <div className="ii-top">
          <div className="info-inputs-cont">
            <CalendlyWidget
            scan={false}
            />
         </div> 
       </div> 
      </div>
    </div>
  );
}
