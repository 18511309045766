import React from "react";
import Services from "./Services/Services";
import Navbar from "./Navbar";
import { Routes, Route } from "react-router-dom";
import Orders from "./Orders/Orders";
import OrderDetailsScan from "./Orders/OrderDetailsScan";
import OrderDetailsDigital from "./Orders/OrderDetailsDigital";
import Candidate from "./Candidate/Candidate";
import "../Styles/media.css";
import AboutUs from "./Resource/AboutUs";
import Home from "../Components/Resource/Home";
import Home2 from "./Resource/Home2";
import PrivacyPolicy from "./Resource/PrivacyPolicy";
import TermsAndCondition from "./Resource/TermsAndCondition";
import Projects from "./Resource/Projects";
import Footer from "./Resource/Footer";
import RegisterForm from "./Orders/Auth/RegisterForm";
import { Toaster } from "react-hot-toast";
import MoreInfo from "./Resource/MoreInfo";
import LoginForm from "./Orders/Auth/LoginForm";
import Unauthorized from "./Resource/Unauthorized";
import ServerError from "./Resource/ServerError";
import NotFoundPage from "./Resource/NotFoundPage";
import ResetPassword from "./Resource/ResetPassword";
import ConfirmNewPassword from "./Resource/ConfirmNewPassword";
import ResetLinkSent from "./Resource/ResetLinkSent";
import PasswordConfirmed from "./Resource/PasswordConfirmed";
import CongratsRegister from "./Resource/CongratsRegister";
import SuccessfulFrom from "../Components/Resource/SuccessfulForm";
import AdminPanel from "../Components/Admin/AdminPanel";
import UserProfile from "./User/UserProfile";
import EmailChangeConfirmation from "./Resource/EmailChangeConfirmation";
import EmailConfirmed from "./Resource/EmailConfirmed";
import ConfirmEmail from "./Resource/ConfirmEmail";
import AdminManagement from "./Admin/Users/AdminManagement";
import UseCases from "./Resource/UseCases";
import Subscription from "./Subscription/Subscription";
import FAQ from "./Resource/FAQ";

export default function Layout() {
  return (
    <div className="layout-container">
      <Toaster position="top-center" reverseOrder={false} />

      <Navbar />

      <Routes>
        <Route exact path="/" element={<Home2 />} />
        <Route exact path="/register" element={<RegisterForm />} />
        <Route exact path="/login" element={<LoginForm />} />
        <Route path="/services/:type" element={<Services />} />
        <Route path="/terms-condition" element={<TermsAndCondition />} />
        <Route path="/projects" element={<Projects />} />
        {/* <Route path="/aboutUs" element={<AboutUs />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/candidate" element={<Candidate />} />
        <Route path="/orders/:type" element={<Orders />} />
        <Route
          exact
          path="/orders/scan/:orderId"
          element={<OrderDetailsScan />}
        />
        <Route
          exact
          path="/orders/digital/:orderId"
          element={<OrderDetailsDigital />}
        />
         <Route path="/subscription/:type" element={<Subscription />} />
        <Route exact path="/schedule-meeting" element={<MoreInfo />} />
        <Route exact path="/unauthorized" element={<Unauthorized />} />
        <Route exact path="/server-error" element={<ServerError />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/new-password" element={<ConfirmNewPassword />} />
        <Route path="/reset-link-sent" element={<ResetLinkSent />} />
        <Route path="/password-confirmed" element={<PasswordConfirmed />} />
        <Route path="/email-confirmed" element={<EmailConfirmed />} />
        <Route path="/congrats-register" element={<CongratsRegister />} />
        <Route path="/request-successful" element={<SuccessfulFrom />} />
        <Route exact path="/admin-panel/*" element={<AdminPanel />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/admin-management" element={<AdminManagement />} />
        <Route path="/use-cases" element={<UseCases/>} />
        <Route path="/faq" element={<FAQ/>} />
        <Route
          path="/confirm-email-change"
          element={<EmailChangeConfirmation />}
        />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
      </Routes>

      <Footer />
    </div>
  );
}
